import React, { useState } from 'react'
import { Grid, AppBar, Tabs, Tab, Box, Typography } from '@material-ui/core';
import ProjectEditorSideGeneral from './ProjectEditorSideGeneral';
import ProjectEditorSideAdd from './ProjectEditorSideAdd';
import ProjectEditorSideCategories from './ProjectEditorSideCategories';
import ProjectEditorSideComments from './ProjectEditorSideComments';
import ProjectEditorSideTasks from './ProjectEditorSideTasks';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ProjectEditorSide (props) {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid style={{ overflowY: 'auto', borderRight: '1px solid #ccc', padding: 0, height: '100%' }} item sm={12} md={3}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    scrollButtons="on"
                >
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Agregar Movimiento" {...a11yProps(1)} />
                    {/* <Tab label="Carga Masiva" {...a11yProps(2)} /> */}
                    <Tab label="Categorías" {...a11yProps(2)} />
                    <Tab label="Cometarios" {...a11yProps(3)} />
                    <Tab label="Tareas" {...a11yProps(4)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <ProjectEditorSideGeneral project={props.project} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ProjectEditorSideAdd project={props.project} />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
                Item Two
            </TabPanel> */}
            <TabPanel value={value} index={2}>
                <ProjectEditorSideCategories project={props.project} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ProjectEditorSideComments project={props.project} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ProjectEditorSideTasks project={props.project} />
            </TabPanel>
        </Grid>
    )
}