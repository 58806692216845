import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import App from './pages/App';
import Client from './pages/Client';
import Login from './pages/Login';
import Registry from './pages/Registry';
import Admin from './pages/Admin';
import ProjectEditor from './pages/ProjectEditor';
import Profile from './pages/Profile';
import Transporter from './pages/Transporter';
import Public from './pages/Public'

const routes = (
    <BrowserRouter>
        <Switch>
            <Route path="/iniciar-sesion" component={Login} exact={true} />
            <Route path="/registro" component={Registry} exact={true} />
            <Route path="/admin" component={Admin} />
            <Route path="/app/projects/:id" component={ProjectEditor} />
            <Route path="/app" component={App} />
            <Route path="/perfil" component={Profile} />
            <Route path="/publico/:id" component={Public} />
            <Route path="/suscripciones/:id" component={Transporter} />
            <Route path="/" component={Client} />
        </Switch>
    </BrowserRouter>
)

ReactDOM.render(routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
