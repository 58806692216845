import React, { useEffect, useState } from 'react';
import firebase from '../firebase'
import { Link, Route, Switch } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Box, Button, AppBar, Toolbar, Typography, Container, Grid, Paper, Card, CardContent, List, ListItem, ListItemText, IconButton, Hidden } from '@material-ui/core';
import { teal } from '@material-ui/core/colors'
import moment from 'moment'

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailIcon from '@material-ui/icons/Mail';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import TimelineIcon from '@material-ui/icons/Timeline';

import ReporteFinanciero from '../components/client/ReporteFinanciero';
import ReportesIndividuales from '../components/client/ReportesIndividuales';
import Expediente from '../components/client/Expediente';
import LineaTiempo from '../components/client/LineaTiempo';
import Precio from '../components/client/Precio';
import Terms from '../components/client/Terms';
import Priv from '../components/client/Priv';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        height: theme.spacing(6)
    },
    cover: {
        height: '80vh',
        backgroundImage: 'url("/images/ft2.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    titleCover: {
        color: '#fff',
        textShadow: '1px 1px 2px rgba(0,0,0,.3)',
        fontWeight: '700',
        lineHeight: 1.1,
        letterSpacing: '2px'
    },
    textCover: {
        color: '#fff',
        textShadow: '1px 1px 2px rgba(0,0,0,.3)',
        fontWeight: '700',
        marginTop: theme.spacing(3),
    },
    tools: {
        backgroundColor: '#fff'
    },
    icon: {
        fontSize: 20,
        color: '#fff',
        marginRight: theme.spacing(1)
    },
    option: {
        padding: theme.spacing(2.5),
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    toolIcon: {
        fontSize: 80,
        color: '#fff',
        marginBottom: theme.spacing(1),
    },
    customersContainer: {
        backgroundColor: '#4458be',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    customersTitle: {
        color: 'rgba(255,255,255,.6)',
        fontSize: theme.spacing(3)
    },
    testContainer: {
        backgroundImage: 'linear-gradient(to bottom, #4458be,#5e35b1)',
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
    },
    footer: {
        backgroundColor: '#5e35b1',
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
    },
}));

const ColorButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: teal[500],
        '&:hover': {
            backgroundColor: teal[400],
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}))(Button);
const WhiteButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: 'rgba(255,255,255,.3)',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,.5)',
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}))(Button);


const Home = () => {

    const classes = useStyles()
    const [logged,setLogged] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.isInitialized().then(user => {
            if (user) {
                setLogged(true)
            }
        })
    },[])

    return (
        <>
            <Box display="flex" alignItems="center" className={classes.cover}>
                <Container>
                    <Typography className={classes.titleCover} align="center" variant="h4" component="h1">Herramientas Inteligentes</Typography>
                    <Typography className={classes.textCover} align="center" variant="h6">Visual e intuitivo. Crea estados financieros, estados de cuenta, expedientes y líneas de tiempo.</Typography>
                    {logged === false && <Box marginTop='1.5rem' display="flex" justifyContent="center">
                        {/* <ColorButton style={{ padding: '.5rem' }} size="large" variant="contained">
                            <MailIcon style={{ marginRight: '.1rem' }} /> Inicia con Google
                        </ColorButton> */}
                        <WhiteButton style={{ marginLeft: '1rem', padding: '.5rem' }} size="large" variant="contained">
                            <AlternateEmailIcon style={{ marginRight: '.5rem' }} /> Inicia con tu Email
                        </WhiteButton>
                    </Box>}
                </Container>
            </Box>
            <Box className={classes.tools} paddingTop="4.5rem" paddingBottom="4.5rem">
                <Container>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Button component={Link} to="/reporte-financiero" fullWidth>
                                <Paper className={classes.option} style={{ backgroundImage: 'linear-gradient(to right bottom, #00b8d4, #26c6da)' }}>
                                    <AccountBalanceIcon className={classes.toolIcon} />
                                    <Typography variant="body2" style={{ color: '#fff', fontWeight: '600' }}>Reporte Financiero</Typography>
                                </Paper>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Button component={Link} to="/reportes-individuales" fullWidth>
                                <Paper className={classes.option} style={{ backgroundImage: 'linear-gradient(to right bottom, #6200ea, #673ab7)' }}>
                                    <AccountBalanceWalletIcon className={classes.toolIcon} />
                                    <Typography variant="body2" style={{ color: '#fff', fontWeight: '600' }}>Reportes Individuales</Typography>
                                </Paper>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Button component={Link} to="/expediente" fullWidth>
                                <Paper className={classes.option} style={{ backgroundImage: 'linear-gradient(to right bottom, #c51162, #e91e63)' }}>
                                    <InsertDriveFileIcon className={classes.toolIcon} />
                                    <Typography variant="body2" style={{ color: '#fff', fontWeight: '600' }}>Expediente</Typography>
                                </Paper>
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Button component={Link} to="/linea-tiempo" fullWidth>
                                <Paper className={classes.option} style={{ backgroundImage: 'linear-gradient(to right bottom, #00bfa5, #4db6ac)' }}>
                                    <TimelineIcon className={classes.toolIcon} />
                                    <Typography variant="body2" style={{ color: '#fff', fontWeight: '600' }}>Linea de Tiempo</Typography>
                                </Paper>
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className={classes.customersContainer}>
                <Container>
                    <Typography style={{ textTransform: 'uppercase' }} variant="subtitle1" component="h3" className={classes.customersTitle} align="center">Algunos de nuestros clientes</Typography>
                    <Grid alignItems="center" style={{ marginTop: '2.5rem' }} container spacing={2}>
                        <Grid item xs={6} sm={4} md={2}>
                            <Button component="a" href="https://asistencia.vip" target="_blank" rel="noopener noreferrer">
                                <img style={{ width: '100%', filter: 'brightness(0) invert(1)', opacity: '.8' }} src="/images/customers/asistencia.png" alt="Asistencia VIP"/>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Button component="a" href="https://administracionvirtual.tech/" target="_blank" rel="noopener noreferrer">
                                <img style={{ width: '100%', filter: 'brightness(0) invert(1)', opacity: '.8' }} src="/images/customers/virtual.png" alt="Administración Virtual"/>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Button component="a" href="https://administracionde.com/" target="_blank" rel="noopener noreferrer">
                                <img style={{ width: '100%', filter: 'brightness(0) invert(1)', opacity: '.8' }} src="/images/customers/galco.png" alt="Galco"/>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Button component="a" href="https://monuu.com/" target="_blank" rel="noopener noreferrer">
                                <img style={{ width: '100%', filter: 'brightness(0) invert(1)', opacity: '.8' }} src="/images/customers/monuu.png" alt="Monuu"/>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Button component="a" href="http://stoq.mx/" target="_blank" rel="noopener noreferrer">
                                <img style={{ width: '100%', filter: 'brightness(0) invert(1)', opacity: '.8' }} src="/images/customers/stoq.png" alt="STOQ"/>
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <Button component="a" href="https://wemanage.com.mx/" target="_blank" rel="noopener noreferrer">
                                <img style={{ width: '100%', filter: 'brightness(0) invert(1)', opacity: '.8' }} src="/images/customers/wemanage.png" alt="We Manage"/>
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box className={classes.testContainer}>
                <Container>
                    <Typography style={{ textTransform: 'uppercase' }} variant="subtitle1" component="h3" className={classes.customersTitle} align="center">Casos de Uso</Typography>
                    <Grid style={{ marginTop: '2.5rem' }} justify="center" alignItems="stretch" container spacing={3}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography align="center" color="textSecondary" gutterBottom>
                                        comparte información a socios e inversionistas
                                    </Typography>
                                    <Typography align="center" variant="h5" component="h2">
                                        Comparte con el mejor diseño
                                    </Typography>
                                    <Typography align="center" style={{ fontSize: '1rem', marginTop: '1rem' }} color="textSecondary">
                                        Mide el crecimiento de tu empresa, analiza cada aspecto de tu empresa con nuestras herramientas y comparte esta información a socios e inversionistas.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography align="center" color="textSecondary" gutterBottom>
                                        gestiona clientes y proveedores
                                    </Typography>
                                    <Typography align="center" variant="h5" component="h2">
                                        Estados de Cuenta Individuales
                                    </Typography>
                                    <Typography align="center" style={{ fontSize: '1rem', marginTop: '1rem' }} color="textSecondary">
                                        La mejor forma de crear estados de cuenta individuales, comparte el proyecto completo o comparte cada hoja individual para conciliar información con clientes y proveedores.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item style={{ display: 'flex' }} xs={12} sm={6} md={4}>
                            <Card style={{ height: '100%' }} className={classes.card}>
                                <CardContent>
                                    <Typography align="center" color="textSecondary" gutterBottom>
                                        controla las finanzas de tu negocio
                                    </Typography>
                                    <Typography align="center" variant="h5" component="h2">
                                        Crea reportes financieros
                                    </Typography>
                                    <Typography align="center" style={{ fontSize: '1rem', marginTop: '1rem' }} color="textSecondary">
                                        Mantén el control de las finanzas de tus negocios, comparte reportes en tiempo real, obtén asistencia de nuestros ejecutivos, gestiona tus deudas y cobranza.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography align="center" color="textSecondary" gutterBottom>
                                        guarda y comparte archivos
                                    </Typography>
                                    <Typography align="center" variant="h5" component="h2">
                                        Crea expedientes
                                    </Typography>
                                    <Typography align="center" style={{ fontSize: '1rem', marginTop: '1rem' }} color="textSecondary">
                                        Controla archivos de tu empresa, de tus clientes, empleados y proveedores. Crea todos los expedientes que necesites y compartelos con la mejor vista y la imagen de tu empresa.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Card className={classes.card}>
                                <CardContent>
                                    <Typography align="center" color="textSecondary" gutterBottom>
                                        controla tus proyectos
                                    </Typography>
                                    <Typography align="center" variant="h5" component="h2">
                                        Líneas de tiempo
                                    </Typography>
                                    <Typography align="center" style={{ fontSize: '1rem', marginTop: '1rem' }} color="textSecondary">
                                        Gestiona tus proyectos, informa a tus clientes el proceso en el que se encuentra, muestra a tus empleados los pasos a seguir para cada uno de tus proyectos.
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default function ButtonAppBar() {

    const classes = useStyles()
    const [logged,setLogged] = useState(false)

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.isInitialized().then(user => {
            if (user) {
                setLogged(true)
            }
        })
    },[])

    return (
        <Box>
            <AppBar position="fixed">
                <Toolbar>
                    <Hidden only={['xs','sm']}>
                        <Link to="/">
                            <img className={classes.logo} src="/images/logo.png" alt="Finansoft"/>
                        </Link>
                    </Hidden>
                    {logged === false ? <Box marginLeft="auto" display="flex">
                        <WhiteButton variant="contained" component={Link} to="/iniciar-sesion">Iniciar Sesión</WhiteButton>
                        <ColorButton variant="contained" style={{ marginLeft: '1.5rem' }} component={Link} to="/registro">Registro</ColorButton>
                    </Box> : <Box marginLeft="auto" display="flex">
                        <WhiteButton variant="contained" component={Link} to="/app">Proyectos</WhiteButton>
                        <ColorButton variant="contained" style={{ marginLeft: '1.5rem' }} component={Link} to="/perfil">Perfil</ColorButton>
                    </Box>}
                </Toolbar>
            </AppBar>
            <Switch>
                <Route path="/" component={Home} exact={true} />
                <Route path="/reporte-financiero" component={ReporteFinanciero} exact={true} />
                <Route path="/reportes-individuales" component={ReportesIndividuales} exact={true} />
                <Route path="/expediente" component={Expediente} exact={true} />
                <Route path="/linea-tiempo" component={LineaTiempo} exact={true} />
                <Route path="/precio" component={Precio} exact={true} />
                <Route path="/terminos-condiciones" component={Terms} exact={true} />
                <Route path="/aviso-privacidad" component={Priv} exact={true} />
            </Switch>
            <Box className={classes.footer}>
                <Container>
                    <Grid container justify="center" spacing={3}>
                        <Grid item xs={10} sm={6} md={3}>
                            <Typography style={{ fontSize: '1rem' }} variant="subtitle1" component="h4" className={classes.customersTitle} align="center">Plataforma</Typography>
                            <List aria-label="secondary mailbox folders">
                                <ListItem component={Link} to="/reporte-financiero" button>
                                    <ListItemText style={{ color: '#fff' }} primary="Reporte Financiero" />
                                </ListItem>
                                <ListItem component={Link} to="/reportes-individuales" button>
                                    <ListItemText style={{ color: '#fff' }} primary="Reportes Individuales" />
                                </ListItem>
                                <ListItem component={Link} to="/expediente" button>
                                    <ListItemText style={{ color: '#fff' }} primary="Expediente" />
                                </ListItem>
                                <ListItem component={Link} to="/linea-tiempo" button>
                                    <ListItemText style={{ color: '#fff' }} primary="Linea de Tiempo" />
                                </ListItem>
                                <ListItem component={Link} to="/precio" button>
                                    <ListItemText style={{ color: '#fff' }} primary="Precio" />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={10} sm={6} md={3}>
                            <Typography style={{ fontSize: '1rem' }} variant="subtitle1" component="h4" className={classes.customersTitle} align="center">Empresa</Typography>
                            <List aria-label="secondary mailbox folders">
                                {/* <ListItem component={Link} to="/blog" button>
                                    <ListItemText style={{ color: '#fff' }} primary="Blog" />
                                </ListItem> */}
                                <ListItem component={Link} to="/terminos-condiciones" button>
                                    <ListItemText style={{ color: '#fff' }} primary="Términos y Condiciones" />
                                </ListItem>
                                <ListItem component={Link} to="/aviso-privacidad" button>
                                    <ListItemText style={{ color: '#fff' }} primary="Aviso de Privacidad" />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid style={{ alignSelf: 'center', }} item xs={10} sm={6} md={3}>
                            <Typography style={{ color: 'rgba(255,255,255,.6)', marginBottom: '1rem' }}>hola@finansoft.tech</Typography>
                            <Typography style={{ color: 'rgba(255,255,255,.6)', marginBottom: '1rem' }}>Alejandro Dumas 71, Polanco IV Secc. Miguel Hidalgo. CP 11550</Typography>
                            <Box display="flex" justifyContent="space-around">
                                <IconButton component="a" href="https://www.facebook.com/Finansoft-105838670897767" target="_blank" rel="noopener noreferrer" color="default"><FacebookIcon style={{ color: '#fff' }} /></IconButton>
                                <IconButton color="default"><TwitterIcon style={{ color: '#fff' }} /></IconButton>
                                <IconButton color="default"><InstagramIcon style={{ color: '#fff' }} /></IconButton>
                            </Box>
                            <Typography style={{ color: 'rgba(255,255,255,.6)', marginTop: '1rem' }}>&copy; {moment().format('YYYY')} Finansoft.</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
}