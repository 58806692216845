import React, { useState, useEffect } from 'react'
import { Box, Container, Typography, Stepper, Step, StepLabel, StepContent, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    background: {
        minHeight: '100vh',
        backgroundImage: 'url("/images/ft5.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed'
    },
    titleCover: {
        color: '#fff',
        textShadow: '1px 1px 2px rgba(0,0,0,.3)',
        fontWeight: '700',
        lineHeight: 1.1,
        letterSpacing: '3px'
    },
    cover: {
        minHeight: '60vh',
    },
    textCover: {
        color: '#fff',
        textShadow: '1px 1px 2px rgba(0,0,0,.3)',
        fontWeight: '700',
        marginTop: theme.spacing(3),
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    stepper: {
        marginTop: theme.spacing(4),
        backgroundColor: 'rgba(255,255,255,.8)',
        borderRadius: theme.spacing(1),
    }
}));

export default function Expediente () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    return (
        <Box className={classes.background}>
            <Box display="flex" alignItems="center" className={classes.cover}>
                <Container>
                    <Typography className={classes.titleCover} align="center" variant="h2" component="h1">Expediente</Typography>
                    <Typography className={classes.textCover} align="center" variant="h6">Sube y comparte expedientes con todos los archivos que necesites.</Typography>
                </Container>
            </Box>
            <Box>
                <Container style={{ paddingBottom: '4rem' }}>
                    <Typography className={classes.titleCover} align="center" variant="h3" component="h2">Comienza paso a paso</Typography>
                    <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
                        <Step>
                            <StepLabel>Comienza creando un proyecto</StepLabel>
                            <StepContent>
                                <Typography>Una vez que hayas iniciado sesión, da click en el boton <b>+ Expediente</b> y serás reenviado inmediatamente a la edición de tu nuevo proyecto</Typography>
                                <img style={{ width: '100%', maxWidth: '20rem' }} src="/images/fs14.png" alt="Comienza creando un proyecto"/>
                                <div className={classes.actionsContainer}>
                                    <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Regresar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === 3 ? 'Has terminado' : 'Siguiente'}
                                    </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Edita los datos generales del proyecto</StepLabel>
                            <StepContent>
                                <Typography>Empieza poniendo un nombre, una descripción, una imagen de portada y agrega a los usuarios involucrados que requiere tu proyecto. Utiliza todas las herramientas disponibles en la barra de navegación, visualiza todas las herramientas dando click en las flechas <b>{'<'} {'>'}</b></Typography>
                                <img style={{ width: '100%', maxWidth: '25rem' }} src="/images/fs10.png" alt="Edita los datos generales del proyecto"/>
                                <div className={classes.actionsContainer}>
                                    <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Regresar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                    >
                                        {activeStep === 3 ? 'Has terminado' : 'Siguiente'}
                                    </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Comienza a agregar archivos</StepLabel>
                            <StepContent>
                                <Typography>Comienza a agregar archivos, únicamente necesitas agregar un nombre, una descripción o comentarios del archivo y seleccionar archivos y dar click en AGREGAR</Typography>
                                <img style={{ width: '100%', maxWidth: '20rem' }} src="/images/fs15.png" alt="Comienza a agregar movimientos"/>
                                <div className={classes.actionsContainer}>
                                    <div>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.button}
                                    >
                                        Regresar
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}
                                        className={classes.button}
                                        disabled={activeStep === 2}
                                    >
                                        {activeStep === 2 ? 'Hemos terminado' : 'Siguiente'}
                                    </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    </Stepper>
                </Container>
            </Box>
        </Box>
    )
}