import React from 'react'
import { Container, Typography } from '@material-ui/core'

export default function Terms () {

    return (
        <>
            <Container style={{ paddingTop: '6rem', paddingBottom: '4rem', minHeight: '100vh' }}>
                <Typography variant="h2" align="center" component="h1">Términos y Condiciones</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>FINANSOFT por STOQ CROWDFUNDING sas de cv, en lo sucesivo (“FINANSOFT”) es una plataforma de venta de inversión en empresas rentables con que hace ganar a los inversionistas mediante utilidades y trading de acciones mediante la plataforma.</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>Nuestras políticas atienden a proteger, a nuestros socios comerciales, a FINANSOFT, y a todos los usuarios de nuestra tienda por lo que se entiende que todos los actos realizados por este medio, son conforme al principio de la buena fe. Al utilizar la plataforma de FINANSOFT, se aceptan todas las condiciones que se enunciarán a continuación y se renuncia expresamente a ejercer cualquier acción que pueda atentar contra el patrimonio de nuestros socios comerciales y de FINANSOFT.</Typography>

                <Typography variant="h3" component="h2" style={{ marginTop: '2rem' }}>Uso</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>¡Bienvenido! FINANSOFT, te ofrece una amplia gama de soluciones para invertir en empresas, bienes raíces y franquicias, plataformas de gestión de inversiones y plataforma de trading de acciones en línea. Cada vez que se utilice la plataforma FINANSOFT, el usuario estará sujeto a los lineamientos, términos y condiciones particulares aplicables a dicho Servicio.</Typography>
                
                <Typography variant="h3" component="h2" style={{ marginTop: '2rem' }}>Comunicaciones Electrónicas</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>Todos los documentos, avisos, mensajes, y otras notificaciones y comunicaciones que le enviemos por medios electrónicos satisfacen cualquier requisito legal de forma escrita y cuentan con plenos efectos de validez.</Typography>

                <Typography variant="h3" component="h2" style={{ marginTop: '2rem' }}>Derechos de autor y propiedad intelectual</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>Todo contenido alojado o puesto a disposición en FINANSOFT es propiedad de FINANSOFT. o de sus proveedores y está protegido por las leyes internacionales y de los Estados Unidos Mexicanos de propiedad intelectual.</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>FINANSOFT respeta la propiedad intelectual de terceros por lo que si cualquier persona considera que su trabajo ha sido reproducido de manera tal que constituya una infracción a derechos de propiedad intelectual, le rogamos que levante un reporte a la dirección de hola@finansoft.tech para que se suspenda la publicación de tal objeto en lo que el equipo de FINANSOFT realiza una investigación con las marcas en cuestión hasta que alguna de las partes logre acreditar el derecho que le confiere materia de la controversia.</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>En cuanto a las marcas, la única relación que tiene FINANSOFT y el usuario es de intermediario con la marca propietaria del servicio en cuestión.</Typography>
            </Container>
        </>
    )
}