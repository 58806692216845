import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { Container, Table, TableHead, TableRow, TableCell, TableBody, TextField, InputAdornment, Paper, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import _ from 'lodash'

import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles(theme => ({
    form: {
        padding: theme.spacing(3),
    },
    table: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto'
    }
}))

export default function PublicCasefile (props) {

    const classes = useStyles()
    const { project } = props
    const [list,setList] = useState([])
    const [search,setSearch] = useState('')


    useEffect(() => {
        if (project.id) {
            firebase.getDocument('projects',project.id).collection('list').orderBy('name').onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                setList(data)
            })
        }
    },[project.id])

    const filter = search ? _.filter(list, item => {
        return _.lowerCase(item.name + ' ' + item.description).match(_.lowerCase(search))
    }) : list

    return (
        <Container style={{ padding: '2rem' }}>
            <Paper className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" colSpan="4">
                                <TextField
                                    label="Buscar Archivo"
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Nombre de Archivo</TableCell>
                            <TableCell>Descripción de Archivo</TableCell>
                            <TableCell>Ver</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            filter.map(item => <TableRow key={item.id}>
                                <TableCell>{moment(item.date).format('DD-MM-YYYY')}</TableCell>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell><IconButton color="primary" component="a" download target="_blank" rel="noopener noreferrer" href={item.file}><VisibilityIcon/></IconButton></TableCell>
                            </TableRow>)
                        }
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    )
}