import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import { Link } from 'react-router-dom'
import { Box, TextField, Grid, Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Menu, MenuItem, Paper, Divider, InputAdornment, Input, InputLabel, FormControl } from '@material-ui/core'
import numeral from 'numeral'
import _ from 'lodash'

import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';

const Item = ({ data, projectId }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [moves,setMoves] = useState([])

    useEffect(() => {
        firebase.getDocument('projects',projectId).collection('list').doc(data.id).collection('moves').onSnapshot(snap => {
            const list = snap.docs.map(doc => doc.data())
            setMoves(list)
        })
    },[data.id,projectId])

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const total = _.reduce(moves, (sum,n) => {
        if (n.type === 'cargo') {
            return sum - n.amount
        } else {
            return sum + n.amount
        }
    },0)

    return (
        <React.Fragment>
            <ListItem>
                <ListItemText
                    primary={data.name}
                    secondary={`Saldo: ${numeral(total).format('$0,0.00')}`}
                />
                <ListItemSecondaryAction>
                    <IconButton color="primary" edge="end" aria-label="options" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                        <MoreVertIcon />
                    </IconButton>
                </ListItemSecondaryAction>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem component={Link} to={`/app/projects/${projectId}/${data.id}`}>Detalle</MenuItem>
                    <MenuItem onClick={handleDelete} style={{ color: '#b71c1c' }}>Eliminar</MenuItem>
                </Menu>
            </ListItem>
            <Divider />
        </React.Fragment>
    )

    async function handleDelete () {
        try {
            firebase.setUsermoves(`projects/${projectId}/list/${data.id}`,data,'Borrar').then(() => {
                firebase.getDocument('projects',projectId).collection('list').doc(data.id).delete().then(() => {
                    alert('Elemento eliminado')
                })
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default function ProjectEditorIndividualList (props) {

    const [name,setName] = useState('')
    const [seeker,setSeeker] = useState('')
    const { list, projectId } = props

    const search = seeker ? _.filter(list, item => {
        return _.lowerCase(item.name + ' ' + item.description).match(_.lowerCase(seeker))
    }) : list

    return (
        <Box>
            <form onSubmit={e => e.preventDefault() && false}>
                <Grid container alignItems="center" spacing={3}>
                    <Grid item sm={12} md={6}>
                        <TextField
                            label="Nombre"
                            variant="outlined"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Button onClick={handleSubmit} size="large" fullWidth color="primary" variant="contained" type="submit">Agregar</Button>
                    </Grid>
                </Grid>
            </form>
            <Paper style={{ marginTop: '2rem', padding: '1rem', }}>
            <FormControl fullWidth>
                <InputLabel htmlFor="input-with-icon-adornment">Buscador ...</InputLabel>
                <Input
                    id="input-with-icon-adornment"
                    value={seeker}
                    onChange={e => setSeeker(e.target.value)}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                />
            </FormControl>
                <List>
                    {
                        search.map(item => <Item projectId={projectId} key={item.id} data={item} />)
                    }
                </List>
            </Paper>
        </Box>
    )

    async function handleSubmit () {
        try {
            if (name) {
                const item = {
                    name
                }
                firebase.addItemOnProject(projectId,'list',item).then(() => {
                    setName('')
                    alert('Elemento agregado')
                })
            } else {
                alert('Escribe un nombre para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}