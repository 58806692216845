import React from 'react'
import { Grid } from '@material-ui/core'

export default function Admin () {

    return (
        <Grid>
            <h1>Finansoft</h1>
        </Grid>
    )
}