import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardMedia, CardContent, Typography, IconButton, Menu, MenuItem, Grid } from '@material-ui/core';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import TimelineIcon from '@material-ui/icons/Timeline';

import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
        position: 'relative'
    },
    media: {
        height: 100,
    },
    title: {
        fontSize: theme.spacing(2)
    },
    options: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 9
    },
    deleteItem: {
        color: theme.palette.secondary.main
    },
    icon: {
        fontSize: 20,
        color: '#fff',
        marginRight: theme.spacing(1)
    }
}));

export default function AppProjectItem (props) {

    const classes = useStyles();
    const id = props.id
    const [project,setProject] = useState(undefined)
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        firebase.getDocument('projects',id).onSnapshot(snap => {
            setProject(snap.data())
        })
    },[id])

    return project ? (
        <Grid item xs={12} sm={4} md={4} lg={3}>
            <Card className={classes.card}>
                <IconButton color="inherit" className={classes.options} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem dense={false} onClick={() => props.handleOpen(id)}>Cambiar de Carpeta</MenuItem>
                    <MenuItem dense={false} className={classes.deleteItem} onClick={handleDelete}>Eliminar</MenuItem>
                </Menu>
                <CardMedia
                    style={{ backgroundColor: '#fff', position: 'relative' }}
                    className={classes.media}
                    component="img"
                    image={project.image ? project.image : `/images/no-image.png`}
                    src={project.image ? project.image : `/images/no-image.png`}
                    title="Contemplative Reptile"
                />
                <CardActionArea component={Link} to={`/app/projects/${id}`}>
                    <CardContent style={{ display: 'flex', alignItems: 'center', backgroundColor: project.color ? project.color : '#2196f3' }}>
                        {/* <Avatar variant="square" style={{ marginRight: '1rem', width: 25, height: 25 }} alt="Icon" src={project.type === 'financial-report' ? "/images/fs1.png" : project.type === 'individual-balance' ? "/images/fs2.png" : project.type === 'case-file' ? "/images/fs3.png" : project.type === 'timeline' ? "/images/fs4.png" : ''} /> */}
                        {project.type === 'financial-report' ? <AccountBalanceIcon className={classes.icon} /> : project.type === 'individual-balance' ? <AccountBalanceWalletIcon className={classes.icon} /> : project.type === 'case-file' ? <InsertDriveFileIcon className={classes.icon} /> : project.type === 'timeline' ? <TimelineIcon className={classes.icon} /> : ''}
                        <Typography style={{ color: '#fff' }} className={classes.title} variant="h6" component="h3">
                            {project.name}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    ) : ''

    async function handleDelete () {
        try {
            firebase.delete('projects',id,project).then(() => {
                alert('Proyecto eliminado con éxito')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}