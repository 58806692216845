import React, { useState, useEffect } from 'react'
import firebase from '../firebase'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, IconButton, Typography, MenuItem, Menu, Grid, Box } from '@material-ui/core'
import moment from 'moment'

import MoreIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import MessageIcon from '@material-ui/icons/Message';
// import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ProjectEditorSide from '../components/project-editor/ProjectEditorSide';
import ProjectEditorMain from '../components/project-editor/ProjectEditorMain';
import ProjectEditorIndividualSide from '../components/project-editor-individual/ProjectEditorIndividualSide'
import ProjectEditorIndividualMain from '../components/project-editor-individual/ProjectEditorIndividualMain'
import ProjectEditorCasefile from '../components/project-editor-casefile/ProjectEditorCasefile'
import ProjectEditorTimeline from '../components/project-editor-timeline/ProjectEditorTimeline'

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
        display: 'block',
        },
    },
    sectionDesktop: {
        // display: 'none',
        // [theme.breakpoints.up('md')]: {
        // display: 'flex',
        // },
        display: 'flex'
    },
    // sectionMobile: {
    //     display: 'flex',
    //     [theme.breakpoints.up('md')]: {
    //     display: 'none',
    //     },
    // },
}));

export default function ProjectEditor (props) {

    const id = props.match.params.id
    const loc = props.location.pathname
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [project,setProject] = useState({})

    useEffect(() => {
        firebase.userSession().then(user => {
            if (user.cutOffDate < moment().valueOf()) {
                alert('Renueva tu suscripción para poder continuar utilizando todas las herramientas de FINANSOFT')
                props.history.push('/perfil')
            }
        })
        firebase.getProject(id).then((snap) => {
            if (snap.status === 'success') {
                firebase.getDocument('projects',snap.id).onSnapshot(project => {
                    setProject(project.data())
                })
            } else {
                alert(snap.status === 'no-perms' ? 'No tienes permisos para editar este proyecto' : 'Proyecto no encontrado')
                props.history.push('/app')
            }
        })
    },[props.history,id])

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Copiar Link para compartir</MenuItem>
            <MenuItem onClick={handleMenuClose}>Ver Reporte</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton aria-label="Comentarios" color="inherit">
                    <MessageIcon />
                </IconButton>
                <p>Comentarios</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="Compartir"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <SendIcon />
                </IconButton>
                <p>Compartir</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <AppBar color="inherit" position="fixed">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        component={Link}
                        to="/app"

                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                        Detalle de Proyecto
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {/* <IconButton component={Link} to="/ayuda" target="_blank" color="inherit">
                            <LiveHelpIcon />
                        </IconButton> */}
                        <IconButton
                            // edge="end"
                            aria-label="Compartir"
                            // aria-controls={menuId}
                            // aria-haspopup="true"
                            // onClick={handleProfileMenuOpen}
                            component={Link}
                            to={`/publico/${id}`}
                            target="_blank"
                            color="inherit"
                        >
                            <SendIcon />
                        </IconButton>
                    </div>
                    {/* <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div> */}
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <Grid container style={{ width: '100%', margin: 0, flex: 1, height: '100vh', paddingTop: '4rem' }} spacing={3}>
                {project.type === 'financial-report' ? <ProjectEditorSide project={project} /> : project.type === 'individual-balance' || project.type === 'case-file' || project.type === 'timeline' ? <ProjectEditorIndividualSide project={project} /> : ''}

                {project.type === 'financial-report' ? <ProjectEditorMain project={project} loc={loc} /> : project.type === 'individual-balance' ? <ProjectEditorIndividualMain project={project} loc={loc} /> : project.type === 'case-file' ? <ProjectEditorCasefile project={project} loc={loc} /> : project.type === 'timeline' ? <ProjectEditorTimeline project={project} loc={loc} /> : ''}
            </Grid>
        </Box>
    )
}