import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { Container, Typography, Stepper, Step, StepLabel, StepContent, Paper } from '@material-ui/core'
import moment from 'moment'

export default function PublicTimeline (props) {

    const { project } = props
    const [list,setList] = useState([])

    useEffect(() => {
        if (project.id) {
            firebase.getDocument('projects',project.id).collection('list').orderBy('date').onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                setList(data)
            })
        }
    },[project.id])
    
    const activeStep = project.activeStep ? project.activeStep : 0

    return (
        <Container style={{ padding: '2rem' }}>
            <Paper>
                <Stepper style={{ marginTop: '2rem' }} activeStep={activeStep} orientation="vertical">
                    {list.map(item => (
                    <Step key={item.id}>
                        <StepLabel>{moment(item.date).format('DD-MM-YYYY')} - {item.name}</StepLabel>
                        <StepContent>
                            <Typography>{item.description}</Typography>
                        </StepContent>
                    </Step>
                    ))}
                </Stepper>
            </Paper>
        </Container>
    )
}