import React, { useState,useEffect } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import firebase from '../firebase';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline, Drawer, AppBar, Toolbar, List, Typography, Divider, IconButton, ListItem, ListItemIcon, ListItemText, ListSubheader, Button, TextField, Avatar, ListItemSecondaryAction, Menu, MenuItem } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import moment from 'moment';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';

import Loader from '../components/Loader';
import AppMain from '../components/app/app-main/AppMain';

const drawerWidth = 280;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24,
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
		width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	paper: {
		padding: theme.spacing(1),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 240,
	},
	orangeAvatar: {
		color: '#fff',
		backgroundColor: teal[300],
	},
	signOutItem: {
		color: theme.palette.secondary.main
	}
}));

export default function App(props) {
	const classes = useStyles();
	const screenWidth = window.screen.width
	const [open, setOpen] = useState(screenWidth > 700 ? true : false);
	const [name, setName] = useState('');
	const [user, setUser] = useState({})
	const [folders, setFolders] = useState([])
	const [projects, setProjects] = useState([])
	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};
	const [loading,setLoading] = useState(true)

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		firebase.isInitialized().then(val => {
			if (val) {
				setLoading(false)
				firebase.userSession().then(user => {
					if (user.cutOffDate < moment().valueOf()) {
						alert('Renueva tu suscripción para poder continuar utilizando todas las herramientas de FINANSOFT')
						props.history.push('/perfil')
					} else {
						setUser(user)
					}
				})
				firebase.getColectionFromUser('folders').orderBy('name').onSnapshot(snap => {
					const data = snap.docs.map(doc => doc.data())
					setFolders(data)
				})
				firebase.getUserProjects().onSnapshot(snap => {
					const data = snap.docs.map(doc => doc.data())
					setProjects(data)
				})
			} else {
				props.history.push('/iniciar-sesion')
			}
		})
	},[props.history])

	const firstLetter = user.name ? user.name.charAt(0) : ''

	const loc = props.location.pathname.split('/')[2]

	return loading === false ? (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
					>
						<MenuIcon />
					</IconButton>
					<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
						Finansoft
					</Typography>
					<IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
						<Avatar className={classes.orangeAvatar}>{firstLetter}</Avatar>
					</IconButton>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem component={Link} to="/perfil">Perfil</MenuItem>
						<MenuItem className={classes.signOutItem} onClick={signOut}>Cerrar Sesión</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
				}}
				open={open}
			>
				<div className={classes.toolbarIcon}>
				<IconButton onClick={handleDrawerClose}>
					<ChevronLeftIcon />
				</IconButton>
				</div>
				<Divider />
				<List dense={true}>
					<ListSubheader inset>Carpetas</ListSubheader>
					<ListItem component={Link} to="/app" style={loc === undefined || loc === '' ? { backgroundColor: '#1565c0', color: '#fff' } : {}} selected={loc === undefined || loc === '' ? true : false} button>
						<ListItemIcon>
							<FolderIcon style={loc === undefined || loc === '' ? { color: '#fff' } : {}} />
						</ListItemIcon>
						<ListItemText primary="Carpeta Principal" />
					</ListItem>
					{
						folders.map(item => <ListItem key={item.id} component={Link} to={`/app/${item.id}`} style={loc === item.id ? { backgroundColor: '#1565c0', color: '#fff', marginTop: '.5rem' } : {marginTop: '.5rem'}} selected={loc === item.id ? true : false} button>
							<ListItemIcon>
								<FolderIcon style={loc === item.id ? { color: '#fff' } : {}} />
							</ListItemIcon>
							<ListItemText primary={item.name} />
							{open === true ? <ListItemSecondaryAction>
								<IconButton size="small" onClick={() => firebase.getColectionFromUser('folders').doc(item.id).delete()} color="inherit" edge="end" aria-label="eliminar">
									<DeleteIcon />
								</IconButton>
							</ListItemSecondaryAction> : ''}
						</ListItem>)
					}
					<Divider style={{ marginTop: '1rem' }} />
					<ListSubheader inset>Nueva Carpeta</ListSubheader>
					{open === true ? <form onSubmit={e => e.preventDefault() && false} style={{ display: 'flex', flexDirection: 'column', padding: '0 .5rem' }}>
						<TextField
							label="Nombre"
							value={name}
							onChange={e => setName(e.target.value)}
							margin="dense"
							variant="outlined"
						/>
						<Button type="submit" onClick={handleSubmit} style={{ marginTop: '.5rem'}} color="primary" variant="contained">Crear</Button>
					</form> : ''}
				</List>
			</Drawer>
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Switch>
					<Route path="/app" render={(props) => <AppMain {...props} userEmail={user.email} folders={folders} projects={projects} />} exact={true} />
					<Route path="/app/:folderId" render={(props) => <AppMain {...props} userEmail={user.email} folders={folders} projects={projects} />} exact={true} />
				</Switch>
			</main>
		</div>
	) : <Loader />

	async function handleSubmit () {
		try {
			if (name) {
				const item = {
					name
				}
				firebase.addOnUser('folders', item).then(id => {
					props.history.push(`/app/${id}`)
					setName('')
				})
			} else {
				alert('Escribe un nombre para continuar')
			}
		} catch (error) {
			alert(error.message)
		}
	}
	async function signOut () {
		try {
			firebase.logout().then(() => {
				props.history.push('/')
			})
		} catch (error) {
			alert(error.message)
		}
	}
}