import React, { useState, useEffect } from 'react'
import firebase from '../firebase'
import { Link } from 'react-router-dom'
import { Box, Container, Typography, Button, Paper } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import moment from 'moment'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import Loader from '../components/Loader'

const useStyles = makeStyles(theme => ({
    cover: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(8),
        backgroundImage: 'linear-gradient(to right bottom, #283593, #5c6bc0)'
    },
    title: {
        marginTop: theme.spacing(8),
        color: '#fff',
        fontSize: theme.spacing(4),
        letterSpacing: '3px'
    } 
}))

const WhiteButton = withStyles(theme => ({
    root: {
        color: '#fff',
        border: '1px solid #fff',
        backgroundColor: 'rgba(255,255,255,.1)',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,.3)',
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}))(Button);

export default function Profile (props) {

    const classes = useStyles()
    const [loading,setLoading] = useState(true)
    const [user,setUser] = useState({})

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.isInitialized().then(user => {
            if (user) {
                firebase.userSession().then(data => {
                    setUser(data)
                    setLoading(false)
                })
            } else {
                props.history.push('/iniciar-sesion')
            }
        })
    },[props.history])

    return loading === false ? (
        <React.Fragment>
                <Box className={classes.cover}>
                    <Container>
                        <Box display="flex" justifyContent="space-between" width="100%">
                            <WhiteButton component={Link} to="/" variant="outlined"><ArrowBackIosIcon/>Inicio</WhiteButton>
                            <WhiteButton component={Link} to="/app" variant="outlined">Área de trabajo <ArrowForwardIosIcon/></WhiteButton>
                        </Box>
                        <Typography className={classes.title} align="center">Perfil</Typography>
                    </Container>
                </Box>
                <Container maxWidth="sm" style={{ paddingBottom: '2rem' }}>
                    <Box marginTop="2rem">
                        <Paper square={true}>
                            <Box borderBottom="1px solid #e0e0e0" padding="1rem">
                                <Typography color="textPrimary" variant="h5">Tu información</Typography>
                            </Box>
                            <Box borderBottom="1px solid #e0e0e0" padding="1rem" display="flex">
                                <Box flex="1">
                                    <Typography color="textSecondary" variant="body1">Nombre:</Typography>
                                </Box>
                                <Box flex="2">
                                    <Typography variant="body1">{user.name} {user.lastName}</Typography>
                                </Box>
                            </Box>
                            <Box borderBottom="1px solid #e0e0e0" padding="1rem" display="flex">
                                <Box flex="1">
                                    <Typography color="textSecondary" variant="body1">Email:</Typography>
                                </Box>
                                <Box flex="2">
                                    <Typography variant="body1">{user.email}</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                        <Box marginTop="2rem">
                            <Paper square={true}>
                                <Box borderBottom="1px solid #e0e0e0" padding="1rem">
                                    <Typography color="textPrimary" variant="h5">Tu suscripción</Typography>
                                </Box>
                                <Box borderBottom="1px solid #e0e0e0" padding="1rem" display="flex">
                                    <Box flex="1">
                                        <Typography color="textSecondary" variant="body1">Tipo de suscipción:</Typography>
                                    </Box>
                                    <Box flex="2">
                                        <Typography variant="body1">{user.suscription === 'monthly' ? 'Pago Mensual' : user.suscription === 'yearly' ? 'Pago Anual' : 'Periodo de Prueba'}</Typography>
                                    </Box>
                                </Box>
                                <Box borderBottom="1px solid #e0e0e0" padding="1rem" display="flex">
                                    <Box flex="1">
                                        <Typography color="textSecondary" variant="body1">Fecha de corte:</Typography>
                                    </Box>
                                    <Box flex="2">
                                        <Typography variant="body1">{moment(user.cutOffDate).format('DD-MM-YYYY')}</Typography>
                                    </Box>
                                </Box>
                                {!user.suscription || user.suscription === 'inactive' ? <Box borderBottom="1px solid #e0e0e0" padding="1rem" display="flex" flexDirection="column">
                                    <Button onClick={() => handleSuscribe('monthly')} fullWidth variant="contained" color="primary">Contratar suscripción mensual por $134.00 mxn mensuales</Button>
                                    <Button onClick={() => handleSuscribe('yearly')} fullWidth style={{ marginTop: '1rem' }} variant="contained" color="primary">Contratar suscripción anual por $98.00 mxn mensuales</Button>
                                </Box> : ''}
                            </Paper>
                        </Box>
                    <Box marginTop="2rem">
                        <Button onClick={logout} variant="contained" color="secondary" fullWidth>
                            <ExitToAppIcon/> Cerrar Sesión
                        </Button>
                    </Box>
                    {/* <Box marginTop="2rem">
                        <Paper square={true}>
                            <Box borderBottom="1px solid #e0e0e0" padding="1rem">
                                <Typography color="textPrimary" variant="h5">Tus Datos Fiscales</Typography>
                            </Box>
                            <Box borderBottom="1px solid #e0e0e0" padding="1rem" display="flex">
                                <Box flex="1">
                                    <Typography color="textSecondary" variant="body1">Tipo de suscipción:</Typography>
                                </Box>
                                <Box flex="2">
                                    <Typography variant="body1">{user.suscription === 'monthly' ? 'Pago Mensual' : user.suscription === 'yearly' ? 'Pago Anual' : 'Periodo de Prueba'}</Typography>
                                </Box>
                            </Box>
                            <Box borderBottom="1px solid #e0e0e0" padding="1rem" display="flex">
                                <Box flex="1">
                                    <Typography color="textSecondary" variant="body1">Fecha de corte:</Typography>
                                </Box>
                                <Box flex="2">
                                    <Typography variant="body1">{moment(user.cutOffDate).format('DD-MM-YYYY')}</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Box> */}
                </Container>
        </React.Fragment>
    ) : <Loader />

    async function handleSuscribe (type) {
        try {
            const item = {
                customerId: user.customerId,
                email: user.email,
                type,
                userId: user.id
            }
            firebase.simpleAdd(item,'suscriptions').then(id => {
                props.history.push(`/suscripciones/${id}`)
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function logout() {
        try {
            firebase.logout().then(() => {
                props.history.push('/')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}