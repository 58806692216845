import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import { Box, Paper, TextField, Table, TableHead, TableRow, TableCell, TableBody, IconButton, TableFooter, FormControl, InputLabel, Select, Fab, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: theme.spacing(2)
    },
    container: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto'
    },
    iconButton: {
        marginLeft: theme.spacing(1)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const Item = (props) => {
    const filtered = props.moves.slice(0,Number(props.index) + 1);
    const {data} = props
    const { projectId, itemId } = props

    const balance = _.reduce(filtered, (sum,n) => {
        if (n.type === 'abono') {
            return sum + n.amount
        } else {
            return sum - n.amount
        }
    },0)

    return (
        <TableRow>
            <TableCell>{moment(data.date).format('DD-MM-YYYY')}</TableCell>
            <TableCell>{data.type}</TableCell>
            <TableCell>{data.concept}</TableCell>
            <TableCell>{data.type === 'cargo' ? numeral(data.amount).format('$0,0.00') : ''}</TableCell>
            <TableCell>{data.type === 'abono' ? numeral(data.amount).format('$0,0.00') : ''}</TableCell>
            <TableCell>{numeral(balance).format('$0,0.00')}</TableCell>
            <TableCell align="center">
                <IconButton onClick={handleDelete} size="small" color="secondary">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableCell>
        </TableRow>
    )

    async function handleDelete () {
        try {
            firebase.setUsermoves(`projects/${projectId}/list/${itemId}/moves/${data.id}`,data,'Borrar').then(() => {
                firebase.getDocument('projects',projectId).collection('list').doc(itemId).collection('moves').doc(data.id).delete(() => {
                    alert('Movimiento eliminado')
                })
            })
        } catch (error) {
            alert(error.message)
        }
    }
}

export default function ProjectEditorIndividualItem (props) {

    const classes = useStyles()
    const id = props.match.params.id
    const { projectId } = props
    const [moves,setMoves] = useState([])
    const [item,setItem] = useState({})
    const [date,setdate] = useState(moment().format('YYYY-MM-DD'))
    const [type,settype] = useState('')
    const [concept,setconcept] = useState('')
    const [amount,setamount] = useState('')

    useEffect(() => {
        firebase.getDocument('projects',projectId).collection('list').doc(id).onSnapshot(snap => {
            setItem(snap.data())
        })
        firebase.getDocument('projects',projectId).collection('list').doc(id).collection('moves').orderBy('date').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setMoves(data)
        })
    },[id,projectId])

    const balance = _.reduce(moves, (sum,n) => {
        if (n.type === 'abono') {
            return sum + n.amount
        } else {
            return sum - n.amount
        }
    },0)

    return (
        <Box>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Detalle (Nombre y Descripción)</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Box>
                        <TextField
                            className={classes.input}
                            label="Nombre"
                            variant="outlined"
                            fullWidth
                            value={item.name ? item.name : ''}
                            onChange={e => firebase.getDocument('projects',projectId).collection('list').doc(id).update({ name: e.target.value })}
                        />
                        <TextField
                            multiline
                            rows="5"
                            label="Descripción"
                            variant="outlined"
                            fullWidth
                            value={item.description ? item.description : ''}
                            onChange={e => firebase.getDocument('projects',projectId).collection('list').doc(id).update({ description: e.target.value })}
                        />
                    </Box>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <Paper className={classes.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Concepto</TableCell>
                            <TableCell>Cargo</TableCell>
                            <TableCell>Abono</TableCell>
                            <TableCell>Saldo</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{Object.keys(moves).map(key => <Item key={key} moves={moves} projectId={projectId} index={key} itemId={id} data={moves[key]} />)}</TableBody>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan="4"></TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>{numeral(balance).format('$0,0.00')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableFooter>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    type="date"
                                    value={date}
                                    onChange={e => setdate(e.target.value)}
                                    fullWidth
                                    label="Fecha"
                                    margin="dense"
                                />
                            </TableCell>
                            <TableCell>
                                <FormControl margin="dense" fullWidth>
                                    <InputLabel htmlFor="outlined-age-native-simple">
                                        Tipo
                                    </InputLabel>
                                    <Select
                                        native
                                        value={type}
                                        onChange={e => settype(e.target.value)}
                                        labelWidth={0}
                                    >
                                        <option value="" />
                                        <option value="cargo">Cargo</option>
                                        <option value="abono">Abono</option>
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell colSpan="2">
                                <TextField
                                    label="Concepto"
                                    type="text"
                                    value={concept}
                                    onChange={e => setconcept(e.target.value)}
                                    fullWidth
                                    margin="dense"
                                />
                            </TableCell>
                            <TableCell colSpan="2">
                                <TextField
                                    label="Monto"
                                    type="number"
                                    value={amount}
                                    onChange={e => setamount(e.target.value)}
                                    fullWidth
                                    margin="dense"
                                />
                            </TableCell>
                            <TableCell><Fab size="small" onClick={handleSubmit} variant="contained" color="primary">Agregar <SendIcon className={classes.iconButton} /></Fab></TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
        </Box>
    )

    async function handleSubmit () {
        try {
            if (date && type && concept && amount) {
                const item = {
                    date: moment(date,'YYYY-MM-DD').valueOf(),
                    type,
                    concept,
                    amount: Number(amount),
                }
                firebase.addItemMove(projectId,id,item).then(() => {
                    settype('')
                    setconcept('')
                    setamount('')
                    alert('Movimiento Agregado')
                })
            } else {
                alert('Completa todos los campos para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}