import React from 'react'
import { Container, Typography } from '@material-ui/core'

export default function Priv () {

    return (
        <>
            <Container style={{ paddingTop: '6rem', paddingBottom: '4rem', minHeight: '100vh' }}>
                <Typography variant="h2" align="center" component="h1">Aviso de Privacidad</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>Aviso de Privacidad Íntegro de FINANSOFT. para la plataforma de herramientas de gestión ubicada en www.finansoft.tech, responsable del tratamiento, protección y confidencialidad de Datos Personales.</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>Los Datos Personales solicitados, son tratados por FINANSOFT., con el fin de brindar una experiencia de compra inmejorable para el usuario y por lo tanto el usuario acepta que FINANSOFT. utilice sus datos enunciativa más no limitativamente para: i) Tener una bases de datos de nuestros clientes; ii) Ejecutar las compras en línea; iii) Levantar su pedido y brindarles el servicio; iv) Realizar la facturación correspondiente, en caso de que así lo requiera; v) Atender sus dudas quejas, sugerencias; y vi) otorgar premios y reconocimientos como muestra de gratitud y compromiso social.</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>Los usuarios de www.finansoft.tech podrán manifestar su negativa para el tratamiento de sus Datos Personales en un plazo de 3 días hábiles después de haber otorgado su consentimiento al presente Aviso de Privacidad. Podrá manifestar su derecho de revocación a través de enviar un correo a: hola@finansoft.tech</Typography>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>Los Datos solicitados para brindar una experiencia de compra excepcional por parte de FINANSOFT, son: i) Nombre completo; ii) RFC; iii) Teléfono fijo y/o celular; iv) Correo electrónico; v) Datos de Domicilio y vi) Datos de Tarjeta de Crédito.</Typography>
            </Container>
        </>
    )
}