import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { Grid, Container, TextField, InputAdornment, Paper, Button, Typography, Stepper, Step, StepLabel, StepContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const useStyles = makeStyles(theme => ({
    form: {
        padding: theme.spacing(3),
    },
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}))

export default function ProjectEditorTimeline (props) {

    const classes = useStyles()
    const { project } = props
    const [list,setList] = useState([])
    const [name,setname] = useState('')
    const [description,setdescription] = useState('')
    const [date,setdate] = useState(moment().format('YYYY-MM-DD'))


    useEffect(() => {
        if (project.id) {
            firebase.getDocument('projects',project.id).collection('list').orderBy('date').onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                setList(data)
            })
        }
    },[project.id])
    
    const activeStep = project.activeStep ? project.activeStep : 0

    return (
        <Grid style={{ overflowY: 'auto', padding: 0, height: '100%' }} item sm={12} md={9}>
            <Container style={{ padding: '2rem' }}>
                <Paper className={classes.form}>
                    <Typography align="center" variant="h6">Agregar Evento</Typography>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                label="Fecha"
                                value={date}
                                onChange={e => setdate(e.target.value)}
                                fullWidth
                                type="date"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <CalendarTodayIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                label="Título"
                                value={name}
                                onChange={e => setname(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                label="Descripción"
                                value={description}
                                onChange={e => setdescription(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button onClick={handleSubmit} fullWidth size="large" variant="contained" color="primary">Agregar</Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Stepper style={{ marginTop: '2rem' }} activeStep={activeStep} orientation="vertical">
                    {list.map(item => (
                    <Step key={item.id}>
                        <StepLabel>{moment(item.date).format('DD-MM-YYYY')} - {item.name}</StepLabel>
                        <StepContent>
                            <Typography>{item.description}</Typography>
                            <div className={classes.actionsContainer}>
                                <div>
                                <Button
                                    disabled={activeStep === 0}
                                    onClick={() => firebase.getDocument('projects',project.id).update({ activeStep:activeStep-1 })}
                                    className={classes.button}
                                >
                                    Regresar
                                </Button>
                                <Button
                                    disabled={activeStep === (list.length-1)}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => firebase.getDocument('projects',project.id).update({ activeStep:activeStep+1 })}
                                    className={classes.button}
                                >
                                    Siguiente
                                </Button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                    ))}
                </Stepper>
            </Container>
        </Grid>
    )

    async function handleSubmit () {
        try {
            if (name && date) {
                const item = {
                    name,
                    description,
                    date: moment(date,'YYYY-MM-DD').valueOf()
                }
                firebase.simpleAdd(item,`projects/${project.id}/list`).then(() => {
                    setname('')
                    setdescription('')
                })
            } else {
                alert('Agrega un nombre y archivo para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}