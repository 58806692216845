import React, { useEffect } from 'react'
import firebase from '../firebase'

import Loader from '../components/Loader'

export default function Transporter (props) {

    const id = props.match.params.id

    useEffect(() => {
        const stripe = window.Stripe('pk_live_GFfJsoQubJbG5DV2w1fCzbaA00agQW1eYF')
        firebase.getDocument('suscriptions',id).onSnapshot(snap => {
            const data = snap.data()
            if (data.status === 'process') {
                console.log(stripe)
                stripe.redirectToCheckout({
                    sessionId: data.stripeData.id
                }).then(result => {
                    alert(result)
                })
            }
        })
    },[id])

    return <Loader />
}