import React,  { useState, useEffect } from 'react';
import firebase from '../firebase';
import { Link } from 'react-router-dom';
import { Avatar, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Paper, Box, Grid, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
		{'Copyright © '}
		<Button component={Link} color="inherit" to="/">
			Finansoft
		</Button>{' '}
		{new Date().getFullYear()}
		{'.'}
		</Typography>
	);
}

const useStyles = makeStyles(theme => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: 'url(https://source.unsplash.com/random)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(8, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function Login(props) {
	const classes = useStyles();

	const [email,setemail] = useState('')
	const [password,setpassword] = useState('')

	useEffect(() => {
		firebase.isInitialized().then(user => {
			if (user) {
				props.history.push('/app')
			}
		})
	}, [props.history])

	return (
		<Grid container component="main" className={classes.root}>
		<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
				<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Inicia Sesión
				</Typography>
				<form onSubmit={e => e.preventDefault() && false} className={classes.form} noValidate>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						label="Email Address"
						autoComplete="email"
						autoFocus
						value={email}
						onChange={e => setemail(e.target.value)}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						label="Password"
						type="password"
						autoComplete="current-password"
						value={password}
						onChange={e => setpassword(e.target.value)}
					/>
					<FormControlLabel
						control={<Checkbox value="remember" color="primary" />}
						label="Remember me"
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={classes.submit}
						onClick={handleSubmit}
					>
						Sign In
					</Button>
					<Grid container>
						<Grid item xs>
							<Button component={Link} to="/iniciar-sesion/olvido-contraseña">
								¿Olvidaste tu contraseña?
							</Button>
						</Grid>
						<Grid item>
							<Button component={Link} to="/registro">
								¿No tienes cuenta? Regístrate
							</Button>
						</Grid>
					</Grid>
					<Box mt={5}>
						<Copyright />
					</Box>
				</form>
				</div>
			</Grid>
		</Grid>
	);

	async function handleSubmit () {
		try {
			firebase.login(email, password).then(() => {
				props.history.push('/app')
			}).catch(error => {
				alert(error.message)
			})
		} catch (error) {
			alert(error.message)
		}
	}
}