import React, { useState } from 'react'
import firebase from '../../firebase'
import { Table, TableHead, TableRow, TableCell, Box, Grid, TableBody, IconButton, TextField, Button, Divider, TableFooter, Modal, FormControl, InputLabel, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

import SendIcon from '@material-ui/icons/Send';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    input: {
        marginBottom: 20
    }
}));

export default function ProjectEditorMainDebts (props) {

    const classes = useStyles()
    const { debts, id, project } = props
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);
    const categories = project.categories ? project.categories : []

    const [conceptPre,setConceptPre] = useState('')
    const [amountPre,setAmountPre] = useState('')

    const [date,setdate] = useState(moment().format('YYYY-MM-DD'))
    const [category,setcategory] = useState('')
    const [concept,setconcept] = useState('')
    const [amount,setamount] = useState('')
    const [itemId,setid] = useState('')

    const handleOpen = (concept,amount,id) => {
        setOpen(true);
        setconcept(concept)
        setamount(amount)
        setid(id)
    };

    const handleClose = () => {
        setOpen(false);
        setconcept('')
        setamount('')
        setid('')
    };

    const total = _.reduce(debts, (sum,n) => {
        return sum + n.amount
    },0)

    return (
        <Box>
            <form onSubmit={e => e.preventDefault() && false}>
                <Grid container alignItems="center" spacing={3}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Concepto"
                            variant="outlined"
                            fullWidth
                            value={conceptPre}
                            onChange={e => setConceptPre(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Monto"
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={amountPre}
                            onChange={e => setAmountPre(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button onClick={handleSubmit} color="primary" fullWidth variant="contained" size="large">Agregar</Button>
                    </Grid>
                </Grid>
            </form>
            <Divider style={{ marginTop: '1rem' }} />
            <div style={{ width: '100%', overflowX: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Concepto</TableCell>
                            <TableCell>Monto</TableCell>
                            <TableCell align="center">Marcar como pagado</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {debts.map(item => <TableRow key={item.id}>
                            <TableCell>{item.concept}</TableCell>
                            <TableCell>{numeral(item.amount).format('$0,0.00')}</TableCell>
                            <TableCell align="center"><IconButton onClick={() => handleOpen(item.concept,item.amount,item.id)} color="primary"><SendIcon/></IconButton></TableCell>
                        </TableRow>)}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell align="right">Total</TableCell>
                            <TableCell>{numeral(total).format('$0,0.00')}</TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title">Marcar como pagado</h2>
                    <p id="simple-modal-description">
                        Una vez que marcas como pagado, este elemento será eliminado de esta lista y agregado a tu estado de cuenta como cargo.
                    </p>
                    <TextField
                        type="date"
                        value={date}
                        onChange={e => setdate(e.target.value)}
                        fullWidth
                        label="Fecha"
                        margin="dense"
                        className={classes.input}
                    />
                    <FormControl className={classes.input} margin="dense" fullWidth>
                        <InputLabel htmlFor="outlined-age-native-simple">
                            Categoría
                        </InputLabel>
                        <Select
                            native
                            value={category}
                            onChange={e => setcategory(e.target.value)}
                            labelWidth={0}
                        >
                            <option value="" />
                            {categories.map(item => <option key={item} value={item}>{item}</option>)}
                        </Select>
                    </FormControl>
                    <TextField
                        label="Concepto"
                        type="text"
                        value={concept}
                        onChange={e => setconcept(e.target.value)}
                        fullWidth
                        margin="dense"
                        className={classes.input}
                    />
                    <TextField
                        label="Monto"
                        type="number"
                        value={amount}
                        onChange={e => setamount(e.target.value)}
                        fullWidth
                        margin="dense"
                        className={classes.input}
                    />
                    <Button onClick={handlePay} variant="contained" fullWidth color="primary">Agregar</Button>
                </div>
            </Modal>
        </Box>
    )



    async function handlePay () {
        try {
            if (date && concept && amount) {
                const item = {
                    date: moment(date,'YYYY-MM-DD').valueOf(),
                    type: 'cargo',
                    category,
                    concept,
                    amount: Number(amount),
                }
                firebase.addBalanceMove(id,item).then(() => {
                    setcategory('')
                    setconcept('')
                    setamount('')
                    firebase.getDocument('projects',id).collection('debts').doc(itemId).update({ status: 'paidOut' }).then(() => {
                        alert('Movimiento Agregado')
                        handleClose()
                    })
                })
            } else {
                alert('Completa todos los campos para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleSubmit () {
        try {
            if (conceptPre && amountPre) {
                const item = {
                    conceptPre,
                    amount: Number(amountPre),
                    status: 'pending'
                }
                firebase.addItemOnProject(id,'debts',item).then(() => {
                    setConceptPre('')
                    setAmountPre('')
                })
            } else {
                alert('Completa todos los campos para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}