import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import numeral from 'numeral'
import _ from 'lodash'

const Item = ({ category,data }) => {

    const filtered = _.filter(data, { 'category': category })

    const totalIncomes = _.reduce(filtered, (sum,n) => {
        if (n.type === 'abono') {
            return sum + n.amount
        }
        return sum
    },0)
    const totalExpenses = _.reduce(filtered, (sum,n) => {
        if (n.type === 'cargo') {
            return sum + n.amount
        }
        return sum
    },0)

    return (
        <TableRow>
            <TableCell>{category}</TableCell>
            <TableCell align="center">{numeral(totalIncomes).format('$0,0.00')}</TableCell>
            <TableCell align="center">{numeral(totalExpenses).format('$0,0.00')}</TableCell>
            <TableCell align="center">{numeral(totalIncomes-totalExpenses).format('$0,0.00')}</TableCell>
        </TableRow>
    )
}

export default function ProjectEditorMainAnalysis (props) {

    const { data } = props
    const uniqCategory = _.uniqBy(data, 'category')
    const categories = uniqCategory.map(item => item.category)

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Categoría</TableCell>
                        <TableCell>Abonos</TableCell>
                        <TableCell>Cargos</TableCell>
                        <TableCell>Total (Abonos - Cargos)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{categories.map(item => <Item key={item} category={item} data={data} />)}</TableBody>
            </Table>
        </div>
    )
}