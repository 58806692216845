import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import { Box, Button, List, Card, CardContent, CardActions, Typography, TextField } from '@material-ui/core'
import moment from 'moment'

export default function ProjectEditorSideComments (props) {

    const [comment,setComment] = useState('')
    const [comments,setComments] = useState([])
    const { project } = props

    useEffect(() => {
        if (project.id) {
            firebase.getDocument('projects',project.id).collection('comments').orderBy('timestamp').onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                setComments(data)
            })
        }
    },[project.id])

    return (
        <Box>
            <form onSubmit={e => e.preventDefault()}>
                <TextField
                    label="Comentarios"
                    multiline
                    rows="5"
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                />
                <Button style={{ marginTop: '.5rem' }} onClick={handleSubmit} variant="contained" fullWidth color="primary" >
                    Agregar
                </Button>
            </form>
            <List>{comments.map(item => <Card style={{ marginTop: '1rem' }} key={item.id}>
                <CardContent>
                    <Typography variant="body1">{item.comment}</Typography>
                </CardContent>
                <CardActions>
                    <Typography variant="caption">{moment(item.timestamp).format('DD-MM-YYYY hh:mm a')}</Typography>
                    <Typography style={{ marginLeft: 'auto' }} variant="caption">{item.email}</Typography>
                </CardActions>
            </Card>)}</List>
        </Box>
    )

    async function handleSubmit () {
        try {
            const item = {
                comment
            }
            firebase.addItemOnProject(project.id,'comments',item).then(() => {
                setComment('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}