import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
import { Link } from 'react-router-dom';
import { Avatar, Button, CssBaseline, TextField, Grid, Box, Typography, Container } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
		{'Copyright © '}
		<Button component={Link} color="inherit" to="/">
			Finansoft
		</Button>{' '}
		{new Date().getFullYear()}
		{'.'}
		</Typography>
	);
}

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
		backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function SignUp(props) {
	const classes = useStyles();

	const [name,setname] = useState('')
	const [lastName,setlastName] = useState('')
	const [email,setemail] = useState('')
	const [password,setpassword] = useState('')

	useEffect(() => {
		firebase.isInitialized().then(user => {
			if (user) {
				props.history.push('/app')
			}
		})
	},[props.history])

	return (
		<Container component="main" maxWidth="xs">
		<CssBaseline />
		<div className={classes.paper}>
			<Avatar className={classes.avatar}>
				<LockOutlinedIcon />
			</Avatar>
			<Typography component="h1" variant="h5">
				Registro
			</Typography>
			<Typography style={{ marginTop: '1rem' }} variant="body1" align="center" color="textSecondary">
				Regístrate ahora y obtén 30 días de prueba sin costo y asesoría de un experto.
			</Typography>
			<form onSubmit={e => e.preventDefault() && false} className={classes.form} noValidate>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
					<TextField
						autoComplete="fname"
						variant="outlined"
						required
						fullWidth
						label="First Name"
						autoFocus
						value={name}
						onChange={e => setname(e.target.value)}
					/>
					</Grid>
					<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						required
						fullWidth
						label="Last Name"
						autoComplete="lname"
						value={lastName}
						onChange={e => setlastName(e.target.value)}
					/>
					</Grid>
					<Grid item xs={12}>
					<TextField
						variant="outlined"
						required
						fullWidth
						label="Email Address"
						autoComplete="email"
						value={email}
						onChange={e => setemail(e.target.value)}
					/>
					</Grid>
					<Grid item xs={12}>
					<TextField
						variant="outlined"
						required
						fullWidth
						label="Password"
						type="password"
						autoComplete="current-password"
						value={password}
						onChange={e => setpassword(e.target.value)}
					/>
					</Grid>
				</Grid>
				<Button
					type="submit"
					fullWidth
					variant="contained"
					color="primary"
					className={classes.submit}
					onClick={handleSubmit}
				>
					Registrarme
				</Button>
				<Grid container justify="flex-end">
					<Grid item>
						<Button component={Link} to="/iniciar-sesion">
							¿Ya tienes una cuenta? Inicia Sesión
						</Button>
					</Grid>
				</Grid>
			</form>
		</div>
		<Box mt={5}>
			<Copyright />
		</Box>
		</Container>
	);

	async function handleSubmit() {
		try {
			firebase.register(name,lastName,email,password).then(() => {
				props.history.push('/app')
			})
		} catch (error) {
			alert(error.message)
		}
	}
}