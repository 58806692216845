import React, { useState } from 'react'
import firebase from '../../firebase'
import { Box, TextField, Button, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'

import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: theme.spacing(2)
    },
	avatar: {
        backgroundColor: blue[900],
        color: '#fff'
    },
    item: {
        padding: '1rem 0',
    }
}))

export default function ProjectEditorSideCategories (props) {

    const classes = useStyles()
    const { project } = props
    const categories = project.categories ? project.categories : []

    const [name,setName] = useState('')

    return (
        <Box>
            <form onSubmit={e => e.preventDefault()}>
                <TextField
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    fullWidth
                    label="Nombre de Categoría"
                    margin="dense"
                    className={classes.input}
                />
                <Button onClick={handleSubmit} variant="contained" fullWidth color="primary" >
                    Agregar
                </Button>
            </form>
            <List>{Object.keys(categories).map(key => <ListItem className={classes.item} key={key}>
                <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                        <FolderIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={categories[key]}
                />
                <ListItemSecondaryAction>
                    <IconButton color="secondary">
                        <DeleteIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>)}</List>
        </Box>
    )

    async function handleSubmit() {
        try {
            var newList = [...categories]
            newList.push(name)
            firebase.getDocument('projects',project.id).update({ categories: newList }).then(() => {
                setName('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}