import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import { Box, Paper, Table, TableHead, TableRow, TableCell, TableBody, ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: theme.spacing(2)
    },
    container: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto'
    },
    iconButton: {
        marginLeft: theme.spacing(1)
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const Item = (props) => {
    const filtered = props.moves.slice(0,Number(props.index) + 1);
    const {data} = props

    const balance = _.reduce(filtered, (sum,n) => {
        if (n.type === 'abono') {
            return sum + n.amount
        } else {
            return sum - n.amount
        }
    },0)

    return (
        <TableRow>
            <TableCell>{moment(data.date).format('DD-MM-YYYY')}</TableCell>
            <TableCell>{data.type}</TableCell>
            <TableCell>{data.concept}</TableCell>
            <TableCell>{data.type === 'cargo' ? numeral(data.amount).format('$0,0.00') : ''}</TableCell>
            <TableCell>{data.type === 'abono' ? numeral(data.amount).format('$0,0.00') : ''}</TableCell>
            <TableCell>{numeral(balance).format('$0,0.00')}</TableCell>
        </TableRow>
    )
}

export default function ProjectEditorIndividualItem (props) {

    const classes = useStyles()
    const id = props.match.params.id
    const { projectId } = props
    const [moves,setMoves] = useState([])
    const [item,setItem] = useState({})

    useEffect(() => {
        firebase.getDocument('projects',projectId).collection('list').doc(id).onSnapshot(snap => {
            setItem(snap.data())
        })
        firebase.getDocument('projects',projectId).collection('list').doc(id).collection('moves').orderBy('date').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setMoves(data)
        })
    },[id,projectId])

    const balance = _.reduce(moves, (sum,n) => {
        if (n.type === 'abono') {
            return sum + n.amount
        } else {
            return sum - n.amount
        }
    },0)

    return (
        <Box>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Detalle (Nombre y Descripción)</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Box>
                        <Typography variant="h6">{item.name ? item.name : ''}</Typography>
                        <Typography style={{ whiteSpace: 'pre-line' }} variant="body1">{item.description ? item.description : ''}</Typography>
                    </Box>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <Paper className={classes.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Concepto</TableCell>
                            <TableCell>Cargo</TableCell>
                            <TableCell>Abono</TableCell>
                            <TableCell>Saldo</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{Object.keys(moves).map(key => <Item key={key} moves={moves} projectId={projectId} index={key} itemId={id} data={moves[key]} />)}</TableBody>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan="4"></TableCell>
                            <TableCell>Total</TableCell>
                            <TableCell>{numeral(balance).format('$0,0.00')}</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </Paper>
        </Box>
    )
}