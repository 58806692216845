import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Container, Typography, Button, Card, CardContent, CardHeader, Divider } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { teal } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    cover: {
        minHeight: '80vh',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingTop: theme.spacing(15),
        backgroundImage: 'url(/images/ft9.png)'
    },
    titleCover: {
        color: '#fff',
        fontWeight: '700',
        lineHeight: 1.1,
        letterSpacing: '3px',
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 22,
    },
    pos: {
        marginBottom: 12,
        fontSize: 42,
        fontWeight: '600'
    },
    cardHeader: {
        fontSize: 12
    }
}));

const ColorButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: teal[500],
        '&:hover': {
            backgroundColor: teal[400],
        },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}))(Button);

export default function Precio () {

    const classes = useStyles()

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <Box style={{ minHeight: '80vh' }}>
            <Box display="flex" className={classes.cover}>
                <Container>
                    <Typography className={classes.titleCover} align="center" variant="h2" component="h1">Precio</Typography>
                    <Box display="flex" justifyContent="center" marginTop="4rem">
                        <Card style={{ backgroundColor: '#1976d2' }} className={classes.card}>
                            <CardHeader
                                titleTypographyProps={{
                                    style:{
                                        fontSize: '16px',
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        color: 'rgba(255,255,255,.7)',
                                        fontWeight: '600'
                                    }
                                }}
                                title="Facturado Anualmente"
                            />
                            <Divider/>
                            <CardContent>
                                <Typography align="center" className={classes.pos} style={{ color: '#fff' }}>
                                    <small>$</small>98<small>.00</small>
                                </Typography>
                                <Typography align="center" variant="body1" component="h4" style={{ color: 'rgba(255,255,255,.6)' }}>
                                    por usuario / por mes
                                </Typography>
                                <ColorButton component={Link} to="/registro" style={{ marginTop: '2rem' }} fullWidth size="large" variant="contained">
                                    Empezar
                                </ColorButton>
                            </CardContent>
                        </Card>
                        <Card style={{ marginLeft: '2rem' }} className={classes.card}>
                            <CardHeader
                                className={classes.cardHeader}
                                titleTypographyProps={{
                                    style:{
                                        fontSize: '16px',
                                        textAlign: 'center',
                                        textTransform: 'uppercase',
                                        fontWeight: '600',
                                        color: 'rgba(63, 81, 181, .7)'
                                    },
                                }}
                                title="Facturado Mensualmente"
                            />
                            <Divider/>
                            <CardContent>
                                <Typography align="center" className={classes.pos} color="primary">
                                    <small>$</small>134<small>.00</small>
                                </Typography>
                                <Typography align="center" variant="body1" component="h4" color="textSecondary">
                                    por usuario / por mes
                                </Typography>
                                <Button component={Link} to="/registro" style={{ marginTop: '2rem' }} fullWidth size="large" variant="outlined" color="primary">
                                    Empezar
                                </Button>
                            </CardContent>
                        </Card>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}