import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { Link, Switch, Route } from 'react-router-dom';
import { Container, Tabs, Tab } from '@material-ui/core'
import _ from 'lodash'

import ProjectEditorIndividualList from './ProjectEditorIndividualList';
import ProjectEditorIndividualItem from './ProjectEditorIndividualItem';

export default function PublicIndividualMain (props) {

    const { project, loc } = props
    const location = loc.split('/')[3]
    const [list,setList] = useState([])

    useEffect(() => {
        if (project.id) {
            firebase.getDocument('projects',project.id).collection('list').onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                setList(data)
            })
        }
    },[project.id])

    const index = _.findIndex(list, { id: location })

    return (
        <Container style={{ padding: '2rem' }}>
            <Tabs
                value={location === '' || location === undefined ? 0 : (index + 1)}
                indicatorColor="primary"
                textColor="primary"
                aria-label="Modo de visualización"
                style={{ marginBottom: '1.5rem' }}
                centered
            >
                <Tab indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" component={Link} to={`/publico/${project.id}`} label="Listado" />
                {
                    list.map(item => <Tab component={Link} to={`/publico/${project.id}/${item.id}`} label={item.name} />)
                }
            </Tabs>
            <Switch>
                <Route path={`/publico/${project.id}`} render={() => <ProjectEditorIndividualList list={list} projectId={project.id} />} exact={true} />
                <Route path={`/publico/${project.id}/:id`} render={props => <ProjectEditorIndividualItem {...props} list={list} projectId={project.id} />} exact={true} />
            </Switch>
        </Container>
    )
}