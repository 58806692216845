import React, { useState, useEffect } from 'react'
import firebase from '../firebase'
import { Link } from 'react-router-dom';
import { Box, Typography, Avatar, IconButton } from '@material-ui/core'

import PublicMain from '../components/public/PublicMain'
import PublicTimeline from '../components/public-timeline/PublicTimeline'
import PublicIndividualMain from '../components/public-individual/PublicIndividualMain'
import PublicCasefile from '../components/public-casefile/PublicCasefile'

export default function Public (props) {

    const id = props.match.params.id
    const loc = props.location.pathname
    const [project,setProject] = useState({})

    useEffect(() => {
        firebase.getDocument('projects',id).onSnapshot(project => {
            setProject(project.data())
        })
    },[id])

    return (
        <Box display="flex" flexDirection="column" style={{ backgroundImage: 'url("/images/ftcover.png")', backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed', minHeight: '100vh' }}>
            <IconButton component={Link} to="/" style={{ position: 'fixed', top: '1rem', left: '1rem' }}>
                <Avatar alt="Finansoft" src="/images/ico-blue.png" />
            </IconButton>
            <img style={{ width: '100%', maxWidth: '10rem', margin: '2rem auto 0' }} src={project.image} alt={project.name}/>
            <Typography style={{ marginTop: '2rem', color: project.color }} variant="h4" align="center">{project.name}</Typography>
            <Typography style={{ width: '100%', maxWidth: '40rem', margin: '2rem auto 0' }} align="center" variant="body1">{project.description}</Typography>
            {project.type === 'financial-report' ? <PublicMain project={project} loc={loc} /> : project.type === 'individual-balance' ? <PublicIndividualMain project={project} loc={loc} /> : project.type === 'case-file' ? <PublicCasefile project={project} loc={loc} /> : project.type === 'timeline' ? <PublicTimeline project={project} loc={loc} /> : ''}
        </Box>
    )
}