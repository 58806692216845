import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { Grid, Container, Table, TableHead, TableRow, TableCell, TableBody, TextField, InputAdornment, Paper, IconButton, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import _ from 'lodash'

import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    form: {
        padding: theme.spacing(3),
    },
    table: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto'
    }
}))

export default function ProjectEditorCasefile (props) {

    const classes = useStyles()
    const { project } = props
    const [list,setList] = useState([])
    const [search,setSearch] = useState('')
    const [loading,setLoading] = useState(false)
    const [name,setname] = useState('')
    const [description,setdescription] = useState('')
    const [file,setfile] = useState(undefined)


    useEffect(() => {
        if (project.id) {
            firebase.getDocument('projects',project.id).collection('list').orderBy('name').onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                setList(data)
            })
        }
    },[project.id])

    const filter = search ? _.filter(list, item => {
        return _.lowerCase(item.name + ' ' + item.description).match(_.lowerCase(search))
    }) : list

    return (
        <Grid style={{ overflowY: 'auto', padding: 0, height: '100%' }} item sm={12} md={9}>
            <Container style={{ padding: '2rem' }}>
                <Paper className={classes.form}>
                    <Typography align="center" variant="h6">Agregar Archivo</Typography>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Nombre de Archivo"
                                value={name}
                                onChange={e => setname(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Descripción de Archivo"
                                value={description}
                                onChange={e => setdescription(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Archivo"
                                fullWidth
                                type="file"
                                onChange={e => setfile(e.target.files[0])}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AttachFileIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button onClick={handleSubmit} disabled={loading} fullWidth size="large" variant="contained" color="primary">Agregar Archivo</Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" colSpan="4">
                                    <TextField
                                        label="Buscar Archivo"
                                        value={search}
                                        onChange={e => setSearch(e.target.value)}
                                        fullWidth
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Eliminar</TableCell>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Nombre de Archivo</TableCell>
                                <TableCell>Descripción de Archivo</TableCell>
                                <TableCell>Ver</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filter.map(item => <TableRow key={item.id}>
                                    <TableCell><IconButton onClick={() => firebase.delete(`projects/${project.id}/list`,item.id,item)} color="secondary"><DeleteIcon/></IconButton></TableCell>
                                    <TableCell>{moment(item.date).format('DD-MM-YYYY')}</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.description}</TableCell>
                                    <TableCell><IconButton color="primary" component="a" download target="_blank" rel="noopener noreferrer" href={item.file}><VisibilityIcon/></IconButton></TableCell>
                                </TableRow>)
                            }
                        </TableBody>
                    </Table>
                </Paper>
            </Container>
        </Grid>
    )

    async function handleSubmit () {
        try {
            setLoading(true)
            if (name && file) {
                const item = {
                    name,
                    description,
                }
                firebase.addCaseFile(item,file,project.id).then(() => {
                    alert('Archivo agregado')
                    setLoading(false)
                })
            } else {
                alert('Agrega un nombre y archivo para continuar')
                setLoading(false)
            }
        } catch (error) {
            alert(error.message)
            setLoading(false)
        }
    }
}