import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { Link, Switch, Route } from 'react-router-dom';
import { Grid, Container, Tabs, Tab } from '@material-ui/core'
import _ from 'lodash'

import ProjectEditorIndividualList from './ProjectEditorIndividualList';
import ProjectEditorIndividualItem from './ProjectEditorIndividualItem';

export default function ProjectEditorIndividualMain (props) {

    const { project, loc } = props
    const location = loc.split('/')[4]
    const [list,setList] = useState([])

    useEffect(() => {
        if (project.id) {
            firebase.getDocument('projects',project.id).collection('list').onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                setList(data)
            })
        }
    },[project.id])

    const index = _.findIndex(list, { id: location })

    return (
        <Grid style={{ overflowY: 'auto', padding: 0, height: '100%' }} item sm={12} md={9}>
            <Container style={{ padding: '2rem' }}>
                <Tabs
                    value={location === '' || location === undefined ? 0 : (index + 1)}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="Modo de visualización"
                    style={{ marginBottom: '1.5rem' }}
                    centered
                >
                    <Tab indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto" component={Link} to={`/app/projects/${project.id}`} label="Listado" />
                    {
                        list.map(item => <Tab component={Link} to={`/app/projects/${project.id}/${item.id}`} label={item.name} />)
                    }
                </Tabs>
                <Switch>
                    <Route path={`/app/projects/${project.id}`} render={() => <ProjectEditorIndividualList list={list} projectId={project.id} />} exact={true} />
                    <Route path={`/app/projects/${project.id}/:id`} render={props => <ProjectEditorIndividualItem {...props} list={list} projectId={project.id} />} exact={true} />
                </Switch>
            </Container>
        </Grid>
    )
}