import React, { useState } from 'react'
import firebase from '../../firebase'
import { TextField, Box, Typography, Button, ListItem, ListItemText, ListItemSecondaryAction, IconButton, List } from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles'
import _ from 'lodash'

import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: '600',
        marginTop: theme.spacing(2),
    },
    form: {
        paddingBottom: theme.spacing(3),
    },
    submitButton: {
        marginTop: theme.spacing(1),
    }
}))

export default function ProjectEditorSideGeneral (props) {

    const classes = useStyles()
    const {project} = props
    const [image,setImage] = useState(undefined)
    const [imageLoading,setImageLoading] = useState(false)
    const [email,setEmail] = useState('')
    const users = project.users ? project.users : []

    return (
        <Box>
            <form className={classes.form} onSubmit={e => e.preventDefault() && false}>
                {project.image ? <picture style={{ display: 'flex', justifyContent: 'center' }}>
                    <img style={{ width: '100%', maxWidth: '5rem' }} src={project.image} alt={project.name}/>
                </picture> : ''}
                <TextField
                    // label="Imagen de Proyecto"
                    fullWidth
                    onChange={e => setImage(e.target.files[0])}
                    margin="dense"
                    variant="outlined"
                    type="file"
                />
                <Button fullWidth onClick={handleImage} className={classes.submitButton} disabled={imageLoading} variant="contained" color="primary">Establecer Imagen</Button>
            </form>
            <TextField
                label="Color"
                fullWidth
                value={project.color ? project.color : ''}
                onChange={e => handleUpdate(e.target.value,'color')}
                margin="dense"
                variant="outlined"
                type="color"
            />
            <TextField
                label="Nombre de Proyecto"
                fullWidth
                value={project.name ? project.name : ''}
                onChange={e => handleUpdate(e.target.value,'name')}
                margin="dense"
                variant="outlined"
            />
            <TextField
                label="Descripción de Proyecto"
                fullWidth
                multiline
                rows="5"
                rowsMax="5"
                value={project.description ? project.description : ''}
                onChange={e => handleUpdate(e.target.value,'description')}
                margin="dense"
                variant="outlined"
                style={{ marginTop: '1rem' }}
            />
            <Typography color="primary" className={classes.title}>Usuarios</Typography>
            <form onSubmit={e => e.preventDefault() && false}>
                <TextField
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    margin="dense"
                    variant="outlined"
                    style={{ marginTop: '1rem' }}
                    type="email"
                />
                <Button type="submit" onClick={handleSubmit} color="primary" variant="contained" fullWidth>Agregar nuevo usuario</Button>
            </form>
            <List>
                {Object.keys(users).map(key => <ListItem key={key}>
                    <ListItemText
                        primary={users[key]}
                    />
                    {project.userEmail !== users[key] ?<ListItemSecondaryAction>
                        <IconButton onClick={() => handleDelete(key)} color="secondary" edge="end" aria-label="delete">
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction> : ''}
                </ListItem>)}
            </List>
        </Box>
    )

    async function handleDelete (index) {
        try {
            var newList = [...users]
            delete newList[index]
            const filtered = _.filter(newList, o => {
                return o
            })
            firebase.updateDoc('projects',project.id,'users',filtered).then(() => {
                alert('Usuario eliminado')
            })
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleSubmit() {
        try {
            const validator = _.find(users,o => {
                return o === email
            })
            if (validator) {
                alert('Usuario ya agregado en lista')
            } else {
                var newList = [...users]
                newList.push(email)
                firebase.updateDoc('projects',project.id,'users',newList).then(() => {
                    setEmail('')
                    alert('Usuario agregado')
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleUpdate(value,index) {
        try {
            firebase.updateDoc('projects',project.id,index,value)
        } catch (error) {
            alert(error.message)
        }
    }
    async function handleImage() {
        setImageLoading(true)
        try {
            if (image) {
                firebase.setProjectImage(project.id,image).then(() => {
                    alert('Imagen subida')
                    setImageLoading(false)
                })
            } else {
                alert('Escoge una imagen para continuar')
                setImageLoading(false)
            }
        } catch (error) {
            alert(error.message)
        }
    }
}