import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

import moment from 'moment'
import _ from 'lodash'

const config = {
    apiKey: "AIzaSyAH4KJAN8z3firYqRAbCxah73ElorVXDDU",
    authDomain: "finansoft-app.firebaseapp.com",
    databaseURL: "https://finansoft-app.firebaseio.com",
    projectId: "finansoft-app",
    storageBucket: "finansoft-app.appspot.com",
    messagingSenderId: "406296962847",
    appId: "1:406296962847:web:7ded4ed68ad674e5d3322a",
    measurementId: "G-YCSDW1RHTE"
}

class Firebase {
    constructor() {
        app.initializeApp(config)
        this.auth = app.auth()
        this.firestore = app.firestore()
        this.storage = app.storage()
    }

    login(email, password) {
        return this.auth.signInWithEmailAndPassword(email,password)
    }
    async register (name,lastName,email,password) {
        const timestamp = moment().valueOf()
        await this.auth.createUserWithEmailAndPassword(email,password)
        return this.firestore.collection('users').doc(this.auth.currentUser.uid).set({
            name,
            lastName,
            email,
            password,
            timestamp,
            id: this.auth.currentUser.uid,
            cutOffDate: moment().add(30,'days').valueOf()
        })
    }
    logout() {
        return this.auth.signOut()
    }

    isInitialized() {
        return new Promise(resolve => {
            this.auth.onAuthStateChanged(resolve)
        })
    }
    userSession() {
        return new Promise(resolve => {
            if (this.auth.currentUser) {
                this.firestore.collection('users').doc(this.auth.currentUser.uid).onSnapshot(snap => {
                    resolve(snap.data())
                })
            }
        })
    }

    async addOnUser(col, item) {
        const ref = this.firestore.collection('users').doc(this.auth.currentUser.uid).collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        await ref.set(item)
        return ref.id
    }
    getColectionFromUser(col) {
        const ref = this.firestore.collection('users').doc(this.auth.currentUser.uid).collection(col)
        return ref
    }
    getUserProjects() {
        const ref = this.firestore.collection('projects').where('users',"array-contains",this.auth.currentUser.email)
        return ref
    }

    async addCaseFile(item,file,projectId) {
        const uploadFile = (file,folder,name) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${name}.${ext}`;
                const uploadFile = this.storage.ref(folder).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const ref = this.firestore.collection('projects').doc(projectId).collection('list').doc()
        const url = await uploadFile(file,projectId,ref.id)
        item.id = ref.id
        item.timestamp = moment().valueOf()
        item.userId = this.auth.currentUser.uid
        item.userEmail = this.auth.currentUser.email
        item.file = url
        await ref.set(item)
        const movesRef = this.firestore.collection('usersMoves').doc()
        const move = {
            col: `projects/${projectId}/list`,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        }
        await movesRef.set(move)
        return ref.id
    }
    async simpleAdd(item,col) {
        const ref = this.firestore.collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        item.userId = this.auth.currentUser.uid
        item.userEmail = this.auth.currentUser.email
        await ref.set(item)
        const movesRef = this.firestore.collection('usersMoves').doc()
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        }
        await movesRef.set(move)
        return ref.id
    }
    async addItemOnProject(projectId,col,item) {
        const ref = this.firestore.collection('projects').doc(projectId).collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        item.userId = this.auth.currentUser.uid
        item.email = this.auth.currentUser.email
        await ref.set(item)
        const movesRef = this.firestore.collection('usersMoves').doc()
        const move = {
            col: `projects/${projectId}/${col}`,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        }
        await movesRef.set(move)
        return ref.id
    }

    getDocument(col,doc) {
        const ref = this.firestore.collection(col).doc(doc)
        return ref
    }
    updateDoc (col,doc,index,value) {
        const ref = this.firestore.collection(col).doc(doc)
        return ref.update({ [index]: value })
    }
    async delete(col,doc,data) {
        const ref = this.firestore.collection(col).doc(doc)
        await ref.delete();
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Borrar',
            what: ref.id,
            userId: this.auth.currentUser.uid,
            data
        };
        return movesRef.set(move);
    }
    async setProjectImage(id,file) {
        const uploadFile = (file,folder,name) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${name}.${ext}`;
                const uploadFile = this.storage.ref(folder).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const url = await uploadFile(file,id,'main')
        return this.firestore.collection('projects').doc(id).update({ image: url })
    }
    setUsermoves(route,data,type) {
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            route,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type,
            userId: this.auth.currentUser.uid,
            data
        };
        return movesRef.set(move);
    }
    async getProject(projectId) {
        const getProject = new Promise (resolve => {
            return this.firestore.collection('projects').doc(projectId).onSnapshot(snap => {
                resolve(snap.data())
            })
        })
        const project = await getProject
        if (project) {
            const finder = _.find(project.users, o => {
                return o === this.auth.currentUser.email
            })
            if (finder) {
                return {status: 'success', id: project.id}
            } else {
                return {status: 'no-perms', id: ''}
            }
        } else {
            return {status: 'no-project', id: ''}
        }
    }
    async addItemMove (projectId,itemId,item) {
        const ref = this.firestore.collection('projects').doc(projectId).collection('list').doc(itemId).collection('moves').doc()
        item.timestamp = moment().valueOf()
        item.id = ref.id
        await this.setUsermoves(`projects/${projectId}/list/${itemId}/moves/${ref.id}`,'add',item)
        return ref.set(item)
    }
    async addBalanceMove(projectId,item) {
        const ref = this.firestore.collection('projects').doc(projectId).collection('moves').doc()
        const projectRef = this.firestore.collection('projects').doc(projectId)
        const getMonths = new Promise (resolve => {
            return projectRef.get().then(snap => {
                const months = snap.data().months
                resolve(months ? months : [])
            })
        })
        var projectMonths = await getMonths
        const month = _.find(projectMonths, o => {
            return o.label === moment(item.date).format('MM-YYYY')
        })
        const monthIndex = _.findIndex(projectMonths, o => {
            return o.label === moment(item.date).format('MM-YYYY')
        })
        const balanceValidator = (month ? month.amount : 0)
        const newBalance = item.type === 'cargo' ? balanceValidator - item.amount : balanceValidator + item.amount
        
        if (monthIndex >= 0) {
            projectMonths[monthIndex].amount = newBalance
            await projectRef.update({ months: projectMonths })
            item.timestamp = moment().valueOf()
            item.id = ref.id
            await this.setUsermoves(`projects/${projectId}/moves/${ref.id}`,'add',item)
            return ref.set(item)
        } else {
            projectMonths.push({ label: moment(item.date).format('MM-YYYY'), amount: newBalance })
            await projectRef.update({ months: projectMonths })
            item.timestamp = moment().valueOf()
            item.id = ref.id
            await this.setUsermoves(`projects/${projectId}/moves/${ref.id}`,'add',item)
            return ref.set(item)
        }
    }
    async removeBalanceMove(projectId,item) {
        const ref = this.firestore.collection('projects').doc(projectId).collection('moves').doc(item.id)
        const projectRef = this.firestore.collection('projects').doc(projectId)
        const getMonths = new Promise (resolve => {
            return projectRef.get().then(snap => {
                const months = snap.data().months
                resolve(months ? months : [])
            })
        })
        var projectMonths = await getMonths
        const month = _.find(projectMonths, o => {
            return o.label === moment(item.date).format('MM-YYYY')
        })
        const monthIndex = _.findIndex(projectMonths, o => {
            return o.label === moment(item.date).format('MM-YYYY')
        })
        const balanceValidator = (month ? month.amount : 0)
        const newBalance = item.type === 'cargo' ? balanceValidator + item.amount : balanceValidator - item.amount
        
        if (monthIndex >= 0) {
            projectMonths[monthIndex].amount = newBalance
            await projectRef.update({ months: projectMonths })
            item.timestamp = moment().valueOf()
            item.id = ref.id
            await this.setUsermoves(`projects/${projectId}/moves/${ref.id}`,'delete',item)
            return ref.delete()
        } else {
            projectMonths.push({ label: moment(item.date).format('MM-YYYY'), amount: newBalance })
            await projectRef.update({ months: projectMonths })
            item.timestamp = moment().valueOf()
            item.id = ref.id
            await this.setUsermoves(`projects/${projectId}/moves/${ref.id}`,'delete',item)
            return ref.delete()
        }
    }
}

export default new Firebase()