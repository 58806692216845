import React from 'react'
import firebase from '../../firebase'
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, FormControl, InputLabel, Select } from '@material-ui/core'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

import DeleteIcon from '@material-ui/icons/Delete';

const Item = (props) => {

    const filtered = props.moves.slice(0,Number(props.index) + 1);
    const {data} = props
    const {categories} = props
    const { projectId } = props

    const balance = _.reduce(filtered, (sum,n) => {
        return sum + n.amount
    },0)

    return (
        <TableRow>
            <TableCell align="center">
                <IconButton onClick={() => firebase.removeBalanceMove(projectId,data)} size="small" color="secondary">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableCell>
            <TableCell>{moment(data.date).format('DD-MM-YYYY')}</TableCell>
            <TableCell>{data.type}</TableCell>
            <TableCell><FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="outlined-age-native-simple">
                    Categoría
                </InputLabel>
                <Select
                    native
                    value={data.category}
                    onChange={e => firebase.getDocument('projects',projectId).collection('moves').doc(data.id).update({ category: e.target.value })}
                    labelWidth={0}
                >
                    <option value="" />
                    {categories.map(item => <option key={item} value={item}>{item}</option>)}
                </Select>
            </FormControl></TableCell>
            <TableCell>{data.concept}</TableCell>
            <TableCell>{numeral(data.amount).format('$0,0.00')}</TableCell>
            <TableCell>{numeral(balance).format('$0,0.00')}</TableCell>
        </TableRow>
    )
}

export default function ProjectEditorMainIncomes (props) {

    const { data, month, project } = props
    const incomes = _.filter(data, { type: 'abono' })
    const categories = project.categories ? project.categories : []
    const { months } = project
    const prevMonth = moment(month, 'MM-YYYY').valueOf()
    const prevObjs = _.filter(months, o => {
        return moment(o.label,'MM-YYYY').valueOf() < prevMonth
    })
    const prevTotal = _.reduce(prevObjs, (sum,o) => {
        return o.amount + sum
    },0)

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Acciones</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Categoría</TableCell>
                        <TableCell>Concepto</TableCell>
                        <TableCell>Monto</TableCell>
                        <TableCell>Acumulado</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{Object.keys(incomes).map(key => <Item key={key} moves={incomes} prevBalance={prevTotal} projectId={project.id} index={key} categories={categories} data={incomes[key]} />)}</TableBody>
            </Table>
        </div>
    )
}