import React, { useState, useEffect } from 'react'
import firebase from '../../../firebase'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography, Box, Button, Modal, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import TimelineIcon from '@material-ui/icons/Timeline';

import AppProjectItem from '../app-project-item/AppProjectItem';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    options: {
        backgroundColor: '#eceff1',
        padding: theme.spacing(2),
        width: '100%',
        margin: 0,
        overflowY: 'auto'
    },
    option: {
        padding: theme.spacing(2),
        width: '100%'
    },
    list: {
        backgroundColor: '#fafafa',
        padding: theme.spacing(2),
        width: '100%',
        margin: 0
    },
    title: {
        color: blue[700],
        borderBottom: `2px solid ${blue[700]}`,
        padding: '.5rem'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    icon: {
        fontSize: 60,
        color: '#fff',
        marginBottom: theme.spacing(1)
    }
}));

export default function AppMain (props) {
    const classes = useStyles()
    const [modalStyle] = useState(getModalStyle);
    const folderId = props.match.params.folderId
    const [folder,setFolder] = useState({})
    const [projects,setProjects] = useState([])
    const [open, setOpen] = useState(false);
    const [category,setcategory] = useState('')
    const [selectedId,setSelectedId] = useState('')
    const { folders } = props

    const handleOpen = (id) => {
        setOpen(true);
        setSelectedId(id);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedId('');
    };

    useEffect(() => {
        if (folderId) {
            const fold = _.find(props.folders, { id: folderId })
            if (fold) {
                setFolder(fold)
                setProjects(fold ? fold.projects : [])
            } else {
                props.history.push('/app')
            }
        } else {
            setFolder(undefined)
            const allProjectsInFolders = _.reduce(props.folders, (arr,n) => {
                return _.concat(arr,n.projects)
            },[])
            const projs = props.projects.map(item => item.id)
            const noneFolder = _.difference(projs,allProjectsInFolders)
            setProjects(noneFolder)
        }
    },[folderId,props.folders,props.projects,props.history])

    return (
        <Box>
            <Grid wrap="nowrap" className={classes.options} container spacing={1}>
                <Grid item style={{ minWidth: '10rem' }} xs={12} sm={4} md={4} lg={3}>
                    <Button onClick={() => handleCreate('financial-report')} style={{ width: '100%' , height: '100%'}}>
                        <Paper className={classes.option} style={{ height: '100%', backgroundImage: 'linear-gradient(to right bottom, #00b8d4, #26c6da)' }}>
                            {/* <img style={{ height: '4rem' }} src="/images/fs1.png" alt="Reporte Financiero" /> */}
                            <AccountBalanceIcon className={classes.icon} />
                            <Typography variant="body2" style={{ color: '#fff', fontWeight: '600' }}>+ Reporte Financiero</Typography>
                        </Paper>
                    </Button>
                </Grid>
                <Grid item style={{ minWidth: '10rem' }} xs={12} sm={4} md={4} lg={3}>
                    <Button onClick={() => handleCreate('individual-balance')} style={{ width: '100%', height: '100%' }}>
                        <Paper className={classes.option} style={{ height: '100%', backgroundImage: 'linear-gradient(to right bottom, #6200ea, #673ab7)' }}>
                            {/* <img style={{ height: '4rem' }} src="/images/fs2.png" alt="Reportes Individuales" /> */}
                            <AccountBalanceWalletIcon className={classes.icon} />
                            <Typography variant="body2" style={{ color: '#fff', fontWeight: '600' }}>+ Reportes Individuales</Typography>
                        </Paper>
                    </Button>
                </Grid>
                <Grid item style={{ minWidth: '10rem' }} xs={12} sm={4} md={4} lg={3}>
                    <Button onClick={() => handleCreate('case-file')} style={{ width: '100%', height: '100%' }}>
                        <Paper className={classes.option} style={{ height: '100%', backgroundImage: 'linear-gradient(to right bottom, #c51162, #e91e63)' }}>
                            {/* <img style={{ height: '4rem' }} src="/images/fs3.png" alt="Expediente" /> */}
                            <InsertDriveFileIcon className={classes.icon} />
                            <Typography variant="body2" style={{ color: '#fff', fontWeight: '600' }}>+ Crear Expediente</Typography>
                        </Paper>
                    </Button>
                </Grid>
                <Grid item style={{ minWidth: '10rem' }} xs={12} sm={4} md={4} lg={3}>
                    <Button onClick={() => handleCreate('timeline')} style={{ width: '100%', height: '100%' }}>
                        <Paper className={classes.option} style={{ height: '100%', backgroundImage: 'linear-gradient(to right bottom, #00bfa5, #4db6ac)' }}>
                            {/* <img style={{ height: '4rem' }} src="/images/fs4.png" alt="Línea de Tiempo" /> */}
                            <TimelineIcon className={classes.icon} />
                            <Typography variant="body2" style={{ color: '#fff', fontWeight: '600' }}>+ Linea de Tiempo</Typography>
                        </Paper>
                    </Button>
                </Grid>
            </Grid>
            <Grid className={classes.list} container spacing={3}>
                <Grid item sm={12}>
                    <Typography className={classes.title} variant="h4" component="h1">{folder ? folder.name : 'Folder Principal'}</Typography>
                </Grid>
                {!_.isEmpty(projects) ? projects.map(value => <AppProjectItem key={value} handleOpen={handleOpen} id={value} />) : <Typography variant="body2" component="p">** No hay proyectos en este folder **</Typography>}
            </Grid>
            <Modal
                aria-labelledby="simple-modal-title"
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.paper}>
                    <h2 id="simple-modal-title">Cambiar proyecto de carpeta</h2>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Categorías</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            onChange={e => setcategory(e.target.value)}
                        >
                            <MenuItem value="">Folder Principal</MenuItem>
                            {folders.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                        </Select>
                        <Button onClick={handleCategory} style={{ marginTop: '1rem' }} variant="contained" fullWidth color="primary">Establecer Categoría</Button>
                </FormControl>
                </div>
            </Modal>
        </Box>
    )

    async function handleCategory () {
        try {
            if (category) {
                if (folder) {
                    const projectList = folder.projects ? folder.projects : []
                    const index = _.findIndex(projectList, o => {
                        return o === selectedId
                    })
                    var projs = [...projectList]
                    delete projs[index]
                    firebase.getColectionFromUser('folders').doc(folder.id).update({ projects: _.filter(projs, o => { return o }) }).then(() => {
                        const selectedFolder = _.findIndex(folders, { id: category })
                        const folderProjects = folders[selectedFolder].projects ? folders[selectedFolder].projects : []
                        folderProjects.push(selectedId)
                        firebase.getColectionFromUser('folders').doc(category).update({ projects: folderProjects}).then(() => {
                            alert('Proyecto agregado a carpeta seleccionada')
                        })
                    })
                } else {
                    const selectedFolder = _.findIndex(folders, { id: category })
                    const folderProjects = folders[selectedFolder].projects ? folders[selectedFolder].projects : []
                    folderProjects.push(selectedId)
                    firebase.getColectionFromUser('folders').doc(category).update({ projects: folderProjects}).then(() => {
                        alert('Proyecto agregado a carpeta seleccionada')
                    })
                }
            } else {
                if (folder) {
                    const projectList = folder.projects ? folder.projects : []
                    const index = _.findIndex(projectList, o => {
                        return o === selectedId
                    })
                    var projectsList = [...projectList]
                    delete projectsList[index]
                    firebase.getColectionFromUser('folders').doc(folder.id).update({ projects: _.filter(projectsList, o => { return o }) }).then(() => {
                        alert('Proyecto agregado a carpeta principal')
                    })
                } else {
                    alert('Este proyecto ya está en la carpeta principal')
                }
            }
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleCreate (type) {
        try {
            const item = {
                name: '',
                type,
                users: [props.userEmail],
            }
            firebase.simpleAdd(item,'projects').then(id => {
                if (folder) {
                    const projectList = folder.projects ? folder.projects : []
                    var projs = [...projectList]
                    projs.push(id)
                    firebase.getColectionFromUser('folders').doc(folder.id).update({ projects: projs }).then(() => {
                        props.history.push(`/app/projects/${id}`)
                    })
                } else {
                    props.history.push(`/app/projects/${id}`)
                }
            })
        } catch (error) {
            alert(error.message)
        }
    }
}