import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import { Link } from 'react-router-dom'
import { Box, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Paper, Divider, InputAdornment, Input, InputLabel, FormControl } from '@material-ui/core'
import numeral from 'numeral'
import _ from 'lodash'

import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

const Item = ({ data, projectId }) => {

    const [moves,setMoves] = useState([])

    useEffect(() => {
        firebase.getDocument('projects',projectId).collection('list').doc(data.id).collection('moves').onSnapshot(snap => {
            const list = snap.docs.map(doc => doc.data())
            setMoves(list)
        })
    },[data.id,projectId])

    const total = _.reduce(moves, (sum,n) => {
        if (n.type === 'cargo') {
            return sum - n.amount
        } else {
            return sum + n.amount
        }
    },0)

    return (
        <React.Fragment>
            <ListItem>
                <ListItemText
                    primary={data.name}
                    secondary={`Saldo: ${numeral(total).format('$0,0.00')}`}
                />
                <ListItemSecondaryAction>
                    <IconButton color="primary" component={Link} to={`/publico/${projectId}/${data.id}`}>
                        <VisibilityIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            <Divider />
        </React.Fragment>
    )
}

export default function ProjectEditorIndividualList (props) {

    const [seeker,setSeeker] = useState('')
    const { list, projectId } = props

    const search = seeker ? _.filter(list, item => {
        return _.lowerCase(item.name + ' ' + item.description).match(_.lowerCase(seeker))
    }) : list

    return (
        <Box>
            <Paper style={{ marginTop: '2rem', padding: '1rem', }}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="input-with-icon-adornment">Buscador ...</InputLabel>
                    <Input
                        id="input-with-icon-adornment"
                        value={seeker}
                        onChange={e => setSeeker(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <List>
                    {
                        search.map(item => <Item projectId={projectId} key={item.id} data={item} />)
                    }
                </List>
            </Paper>
        </Box>
    )
}