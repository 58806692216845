import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import { Link, Switch, Route } from 'react-router-dom';
import { Container, Grid, Table, TableHead, TableRow, TableCell, TableBody, IconButton, FormControl, InputLabel, Select, Box, Typography, Button, Tabs, Tab, Card, CardContent, CardActions, Paper } from '@material-ui/core';
import { AreaChart, XAxis, YAxis, CartesianGrid, Tooltip, Area, ResponsiveContainer, PieChart, Pie } from 'recharts'
import { makeStyles } from '@material-ui/core/styles'
import numeral from 'numeral'
import moment from 'moment'
import _ from 'lodash'

import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ProjectEditorMainIncomes from './ProjectEditorMainIncomes';
import ProjectEditorMainExpenses from './ProjectEditorMainExpenses';
import ProjectEditorMainDebts from './ProjectEditorMainDebts';
import ProjectEditorMainReceivable from './ProjectEditorMainReceivable';
import ProjectEditorMainAnalysis from './ProjectEditorMainAnalysis';

const useStyles = makeStyles(theme => ({
    cardTitle: {
        marginBottom: theme.spacing(2)
    },
    resumeCard: {
        height: 150,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(2),
        width: '100%'
    }
}))

const Item = (props) => {

    const filtered = props.moves.slice(0,Number(props.index) + 1);
    const {data} = props
    const {categories} = props
    const { projectId } = props
    const { prevBalance } = props

    const balance = _.reduce(filtered, (sum,n) => {
        if (n.type === 'abono') {
            return sum + n.amount
        } else {
            return sum - n.amount
        }
    },prevBalance)

    return (
        <TableRow>
            <TableCell align="center">
                <IconButton onClick={() => firebase.removeBalanceMove(projectId,data)} size="small" color="secondary">
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </TableCell>
            <TableCell>{moment(data.date).format('DD-MM-YYYY')}</TableCell>
            <TableCell>{data.type}</TableCell>
            <TableCell><FormControl margin="dense" fullWidth>
                <InputLabel htmlFor="outlined-age-native-simple">
                    Categoría
                </InputLabel>
                <Select
                    native
                    value={data.category}
                    onChange={e => firebase.getDocument('projects',projectId).collection('moves').doc(data.id).update({ category: e.target.value })}
                    labelWidth={0}
                >
                    <option value="" />
                    {categories.map(item => <option key={item} value={item}>{item}</option>)}
                </Select>
            </FormControl></TableCell>
            <TableCell>{data.concept}</TableCell>
            <TableCell>{data.type === 'abono' ? numeral(data.amount).format('$0,0.00') : ''}</TableCell>
            <TableCell>{data.type === 'cargo' ? numeral(data.amount).format('$0,0.00') : ''}</TableCell>
            <TableCell>{numeral(balance).format('$0,0.00')}</TableCell>
        </TableRow>
    )
}

const Resume = (props) => {

    const classes = useStyles()
    const { data, month, project, debts, receivable, id } = props
    const { months } = project
    const prevMonth = moment(month, 'MM-YYYY').valueOf()
    const objsToMonth = _.filter(months, o => {
        return moment(o.label,'MM-YYYY').valueOf() <= prevMonth
    })
    const actualBalance = _.reduce(objsToMonth, (sum,o) => {
        return o.amount + sum
    },0)
    const currentMonth = _.find(months, o => {
        return o.label === month
    })
    const totalIncomes = _.reduce(data,(sum,n) => {
        if (n.type === 'abono') {
            return sum + n.amount
        }
        return sum
    }, 0)
    const totalExpenses = _.reduce(data,(sum,n) => {
        if (n.type === 'cargo') {
            return sum + n.amount
        }
        return sum
    }, 0)
    const totalDebts = _.reduce(debts, (sum,n) => {
        return sum + n.amount
    },0)
    const totalReceivable = _.reduce(receivable, (sum,n) => {
        return sum + n.amount
    },0)

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
                <Paper className={classes.resumeCard} square={true} color="primary">
                    <Typography className={classes.cardTitle} align="center" variant="subtitle1">Saldo actual:</Typography>
                    <Typography align="center" variant="body1">{numeral(actualBalance).format('$0,0.00')}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper className={classes.resumeCard} square={true} color="primary">
                    <Typography className={classes.cardTitle} align="center" variant="subtitle1">Balance del mes <br/> (ingresos vs egresos):</Typography>
                    <Typography align="center" variant="body1">{numeral(currentMonth ? currentMonth.amount : 0).format('$0,0.00')}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper className={classes.resumeCard} square={true} color="primary">
                    <Typography className={classes.cardTitle} align="center" variant="subtitle1">Saldo con cuentas por cobrar y cuentas por pagar:</Typography>
                    <Typography align="center" variant="body1">{numeral(actualBalance + totalReceivable - totalDebts).format('$0,0.00')}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
                <ResponsiveContainer height={250} width="100%">
                    <PieChart>
                        <Pie data={[{label: 'Ingresos',amount:totalIncomes},{label:'Egresos',amount:totalExpenses}]} dataKey="amount" nameKey="label" innerRadius={40} outerRadius={80} fill="#303f9f" label />
                    </PieChart>
                </ResponsiveContainer>
            </Grid>
            <Grid item xs={12} md={8}>
                <ResponsiveContainer height={250} width="100%">
                    <AreaChart data={_.orderBy(months, ['label'],['asc'])}>
                        <defs>
                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="label" />
                        <YAxis />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Area type="monotone" dataKey="amount" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
                    </AreaChart>
                </ResponsiveContainer>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography align="center" className={classes.cardTitle} variant="h4">Ingresos</Typography>
                        <Typography align="center" variant="body1">{numeral(totalIncomes).format('$0,0.00')}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button component={Link} to={`/app/projects/${id}/ingresos`} fullWidth color="primary" size="small">Ver todos los ingresos <ChevronRightIcon /></Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography align="center" className={classes.cardTitle} variant="h4">Egresos</Typography>
                        <Typography align="center" variant="body1">{numeral(totalExpenses).format('$0,0.00')}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button component={Link} to={`/app/projects/${id}/egresos`} fullWidth color="primary" size="small">Ver todos los egresos <ChevronRightIcon /></Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography align="center" className={classes.cardTitle} variant="h4">Cuentas por cobrar</Typography>
                        <Typography align="center" variant="body1">{numeral(totalReceivable).format('$0,0.00')}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button component={Link} to={`/app/projects/${id}/cuentas-cobrar`} fullWidth color="primary" size="small">Ver y agregar las cuentas por cobrar <ChevronRightIcon /></Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardContent>
                        <Typography align="center" className={classes.cardTitle} variant="h4">Cuentas por pagar</Typography>
                        <Typography align="center" variant="body1">{numeral(totalDebts).format('$0,0.00')}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button component={Link} to={`/app/projects/${id}/cuentas-pagar`} fullWidth color="primary" size="small">Ver y agregar las cuentas por pagar <ChevronRightIcon /></Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}

const Balance = (props) => {

    const { data, month, project } = props
    const categories = project.categories ? project.categories : []
    const { months } = project
    const prevMonth = moment(month, 'MM-YYYY').valueOf()
    const prevObjs = _.filter(months, o => {
        return moment(o.label,'MM-YYYY').valueOf() < prevMonth
    })
    const prevTotal = _.reduce(prevObjs, (sum,o) => {
        return o.amount + sum
    },0)

    return (
        <div style={{ width: '100%', overflowX: 'auto' }}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Acciones</TableCell>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Tipo</TableCell>
                        <TableCell>Categoría</TableCell>
                        <TableCell>Concepto</TableCell>
                        <TableCell>Abonos</TableCell>
                        <TableCell>Cargos</TableCell>
                        <TableCell>Balance</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{Object.keys(data).map(key => <Item key={key} moves={data} prevBalance={prevTotal} projectId={project.id} index={key} categories={categories} data={data[key]} />)}</TableBody>
            </Table>
        </div>
    )
}

export default function ProjectEditorMain(props) {
    

    const [data,setData] = useState([]);
    const [receivable,setReceivable] = useState([])
    const [debts,setDebts] = useState([])
    const [month,setMonth] = useState(undefined)
    const { project, loc } = props
    const location = loc.split('/')[4]

    useEffect(() => {
        const current = moment().format('MM-YYYY')
        setMonth(current)
    },[])

    useEffect(() => {
        const initialMonth = moment(month,'MM-YYYY').startOf('month').valueOf()
        const endMonth = moment(month,'MM-YYYY').endOf('month').valueOf()
        if (project.id && initialMonth && endMonth) {
            firebase.getDocument('projects',project.id).collection('moves').orderBy('date').where('date','<=',endMonth).where('date','>=',initialMonth).onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                setData(data)
            })
            firebase.getDocument('projects',project.id).collection('receivable').where('status','==','pending').onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                setReceivable(data)
            })
            firebase.getDocument('projects',project.id).collection('debts').where('status','==','pending').onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                setDebts(data)
            })
        }
    },[month,project.id])

    return (
        <Grid style={{ overflowY: 'auto', padding: 0, height: '100%' }} item sm={12} md={9}>
            <Container style={{ padding: '2rem' }}>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '2rem' }}>
                    <IconButton onClick={() => setMonth(moment(month,'MM-YYYY').subtract(1,'month').format('MM-YYYY'))} color="primary" size="medium">
                        <ChevronLeftIcon fontSize="large" />
                    </IconButton>
                    <Typography align="center" style={{ margin: '0 2rem', }} color="primary" variant="h6">{month}</Typography>
                    <IconButton onClick={() => setMonth(moment(month,'MM-YYYY').add(1,'month').format('MM-YYYY'))} color="primary" size="medium">
                        <ChevronRightIcon fontSize="large" />
                    </IconButton>
                </Box>
                <Tabs
                    value={location === 'estado-cuenta' ? 1 : location === 'analisis' ? 2 : location === '' || location === undefined ? 0 : 3}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="Modo de visualización"
                    style={{ marginBottom: '1.5rem' }}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    <Tab component={Link} to={`/app/projects/${project.id}`} label="Resumen" />
                    <Tab component={Link} to={`/app/projects/${project.id}/estado-cuenta`} label="Estado de Cuenta" />
                    <Tab component={Link} to={`/app/projects/${project.id}/analisis`} label="Análisis" />
                    <Tab label="Otro" disabled />
                </Tabs>
                <Switch>
                    <Route path={`/app/projects/${project.id}`} render={() => <Resume receivable={receivable} debts={debts} month={month} project={project} data={data} id={project.id} />} exact={true} />
                    <Route path={`/app/projects/${project.id}/estado-cuenta`} render={() => <Balance month={month} project={project} data={data} />} exact={true} />
                    <Route path={`/app/projects/${project.id}/ingresos`} render={() => <ProjectEditorMainIncomes receivable={receivable} debts={debts} month={month} project={project} data={data} id={project.id} />} exact={true} />
                    <Route path={`/app/projects/${project.id}/egresos`} render={() => <ProjectEditorMainExpenses receivable={receivable} debts={debts} month={month} project={project} data={data} id={project.id} />} exact={true} />
                    <Route path={`/app/projects/${project.id}/cuentas-pagar`} render={() => <ProjectEditorMainDebts receivable={receivable} debts={debts} month={month} project={project} data={data} id={project.id} />} exact={true} />
                    <Route path={`/app/projects/${project.id}/cuentas-cobrar`} render={() => <ProjectEditorMainReceivable receivable={receivable} debts={debts} month={month} project={project} data={data} id={project.id} />} exact={true} />
                    <Route path={`/app/projects/${project.id}/analisis`} render={() => <ProjectEditorMainAnalysis receivable={receivable} debts={debts} month={month} project={project} data={data} id={project.id} />} exact={true} />
                </Switch>
            </Container>
        </Grid>
    );
}