import React, { useState, useEffect } from 'react'
import firebase from '../../firebase'
import { Box, Button, List, Card, CardContent, CardActions, Typography, TextField, IconButton } from '@material-ui/core'
import moment from 'moment'

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function ProjectEditorSideTasks (props) {

    const [task,settask] = useState('')
    const [tasks,settasks] = useState([])
    const { project } = props

    useEffect(() => {
        if (project.id) {
            firebase.getDocument('projects',project.id).collection('tasks').orderBy('isRead').orderBy('timestamp').onSnapshot(snap => {
                const data = snap.docs.map(doc => doc.data())
                settasks(data)
            })
        }
    },[project.id])

    return (
        <Box>
            <form onSubmit={e => e.preventDefault()}>
                <TextField
                    label="Tarea"
                    value={task}
                    onChange={e => settask(e.target.value)}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                />
                <Button style={{ marginTop: '.5rem' }} onClick={handleSubmit} variant="contained" fullWidth color="primary" >
                    Agregar
                </Button>
            </form>
            <List>{tasks.map(item => <Card style={{ marginTop: '1rem' }} key={item.id}>
                <CardContent>
                    <Typography variant="body1">{item.task}</Typography>
                </CardContent>
                <CardActions>
                    <Typography variant="caption">{moment(item.timestamp).format('DD-MM-YYYY hh:mm a')}</Typography>
                    <IconButton onClick={() => firebase.getDocument('projects',project.id).collection('tasks').doc(item.id).update({ isRead: !item.isRead })} style={{ marginLeft: 'auto' }} color="primary" variant="caption">
                        {
                            item.isRead === true ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />
                        }
                    </IconButton>
                </CardActions>
            </Card>)}</List>
        </Box>
    )

    async function handleSubmit () {
        try {
            const item = {
                task,
                isRead: false
            }
            firebase.addItemOnProject(project.id,'tasks',item).then(() => {
                settask('')
            })
        } catch (error) {
            alert(error.message)
        }
    }
}