import React, { useState } from 'react'
import firebase from '../../firebase'
import { Box, TextField, FormControl, InputLabel, Select, Button } from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: 20
    }
}))

export default function ProjectEditorSideAdd (props) {

    const classes = useStyles()
    const {project} = props

    const categories = project.categories ? project.categories : []

    const [date, setdate] = useState(moment().format('YYYY-MM-DD'))
    const [type, settype] = useState('')
    const [category, setcategory] = useState('')
    const [concept, setconcept] = useState('')
    const [amount, setamount] = useState('')

    return (
        <Box>
            <TextField
                type="date"
                value={date}
                onChange={e => setdate(e.target.value)}
                fullWidth
                label="Fecha"
                margin="dense"
                className={classes.input}
            />
            <FormControl className={classes.input} margin="dense" fullWidth>
                <InputLabel htmlFor="outlined-age-native-simple">
                    Tipo
                </InputLabel>
                <Select
                    native
                    value={type}
                    onChange={e => settype(e.target.value)}
                    labelWidth={0}
                >
                    <option value="" />
                    <option value="cargo">Cargo</option>
                    <option value="abono">Abono</option>
                </Select>
            </FormControl>
            <FormControl className={classes.input} margin="dense" fullWidth>
                <InputLabel htmlFor="outlined-age-native-simple">
                    Categoría
                </InputLabel>
                <Select
                    native
                    value={category}
                    onChange={e => setcategory(e.target.value)}
                    labelWidth={0}
                >
                    <option value="" />
                    {categories.map(item => <option key={item} value={item}>{item}</option>)}
                </Select>
            </FormControl>
            <TextField
                label="Concepto"
                type="text"
                value={concept}
                onChange={e => setconcept(e.target.value)}
                fullWidth
                margin="dense"
                className={classes.input}
            />
            <TextField
                label="Monto"
                type="number"
                value={amount}
                onChange={e => setamount(e.target.value)}
                fullWidth
                margin="dense"
                className={classes.input}
            />
            <Button onClick={handleSubmit} variant="contained" fullWidth color="primary">Agregar</Button>
        </Box>
    )

    async function handleSubmit () {
        try {
            if (date && type && concept && amount) {
                const item = {
                    date: moment(date,'YYYY-MM-DD').valueOf(),
                    type,
                    category,
                    concept,
                    amount: Number(amount),
                }
                firebase.addBalanceMove(project.id,item).then(() => {
                    settype('')
                    setcategory('')
                    setconcept('')
                    setamount('')
                    alert('Movimiento Agregado')
                })
            } else {
                alert('Completa todos los campos para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}